<template>
  <div class="antialiased bg-white dark:bg-gray-900 min-h-screen">
    <TopNav @toggle-sidebar="toggleSidebar"  />
    <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />

    <main class="p-6 md:ml-64 h-auto pt-20 bg-gray-50">
      <div class="max-w-screen-2xl w-full md:ml-64 px-6 mx-auto">
        <!-- Header -->
        <div class="mb-6 block">


          <router-link :to="{ name: 'AllUsersReports' }"
          class="mt-4 ml-4 flex items-center text-primary-700 dark:text-primary-300 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            <span class="ml-2">Back to user reports</span>
          </router-link>
        </div>
        <header class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
          <div>
            <div v-if="!loading">
              <h1 class="text-4xl font-bold text-gray-800 mb-2">
                {{ user.first_name }} {{ user.last_name }} - User Report
              </h1>
              <p class="text-gray-500 text-sm">
                {{ user.email || '' }}
              </p>
            </div>
              <h1 class="text-4xl font-bold text-gray-800 mb-2" v-else>
                User Report
              </h1>
            <p class="text-gray-500 text-sm">View user statistics, activity, and performance details</p>
          </div>
          <!-- <router-link :to="{ name: 'EditUser', params: { id: user.id } }"
            class="bg-primary-700 text-white font-semibold hover:bg-primary-800 py-2 px-4 rounded-lg shadow-md">
            Edit User
          </router-link> -->
        </header>

        <!-- Tab Navigation -->
        <div class="border-b border-gray-200 dark:border-gray-700">
          <nav class="flex space-x-6">
            <button v-for="tab in tabs" :key="tab.id" @click="activeTab = tab.id" :class="[
              'py-4 px-6 text-sm font-medium',
              activeTab === tab.id
                ? 'text-primary-700 border-b-2 border-primary-700'
                : 'text-gray-600 hover:text-gray-800'
            ]">
              {{ tab.label }}
            </button>
          </nav>
        </div>

        <!-- Tab Content -->
        <section class="mt-6">
          <!-- Stats Bar -->
          <!-- Stats Bar -->
          <div class="grid grid-cols-5 gap-2 mb-8">
            <StatCard title="Courses Assigned" :value="summary.totalCourses" icon="completion-rate" />
            <StatCard title="Completion Rate" :value="summary.completionRate + '%'" icon="completion-rate" />
            <StatCard title="Completed Courses" :value="summary.completedCourses" icon="completed-courses" />
            <StatCard title="In Progress" :value="stats.coursesInProgress" icon="in-progress" />
            <StatCard title="Courses Not Started" :value="stats.notStartedCount" icon="not-started" />
          </div>



          <!-- Overview Tab -->
          <div v-if="activeTab === 'overview'" class="bg-white shadow-md rounded-lg p-6">
            <div v-if="progressDataLoading" class="text-center py-10">
              <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
            <h2 class="text-xl font-semibold text-gray-800 mb-4">User Overview</h2>
            <p class="text-gray-600 mb-5">
              This section provides a summary of the user's performance and progress across the platform.
            </p>
            <!-- Progress Charts -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
              <!-- Course Progress Chart -->
              <div class="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-screen-lg">
                <h2 class="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-3">Course Progress</h2>
                <canvas v-show="user.enrolledCourses.length > 0" id="progressBarChart" class="max-h-96"></canvas>
                <p v-show="user.enrolledCourses.length === 0" class="text-center text-gray-600 dark:text-gray-400">
                  No courses available for progress data.
                </p>
              </div>
              
              <!-- Course Status Chart -->
              <div class="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-screen-lg">
                <h2 class="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-3">Course Status</h2>
                <canvas v-if="summary.totalCourses > 0" id="statusPieChart" class="max-h-96"></canvas>
                <p v-else class="text-center text-gray-600 dark:text-gray-400">No course status data available.</p>
              </div>
              </div>
            </div>
          </div>

          <!-- Courses Tab -->
          <div v-if="activeTab === 'courses'" class="bg-white shadow-md rounded-lg p-6">
            <h2 class="text-xl font-semibold text-gray-800 mb-4">Courses</h2>
            <div v-if="loading" class="text-center py-10">
              <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>            
            <div v-else>
              <!-- Table -->
              <div v-if="user.enrolledCourses.length > 0">
                <table
                  class="min-w-full table-auto border-collapse bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-700">
                  <thead>
                    <tr>
                      <th class="px-4 py-3 text-center text-sm font-semibold text-gray-800 dark:text-gray-300">Status</th>
                      <th class="px-4 py-3 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">Course Name</th>
                      <th class="px-4 py-3 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">Progress</th>
                      <th class="px-4 py-3 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">Units Completed</th>
                      <th class="px-4 py-3 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">Time Spent</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="course in user.enrolledCourses" :key="course.id"
                      class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                      @click="openCourseModal(course)">
                      <td class="px-4 py-3 text-center">
                        <span :class="statusMarkerClass(course.completionPercentage)"
                          class="inline-block w-4 h-4 rounded-full"></span>
                      </td>
                      <td class="px-4 py-3">{{ course.course_title }}</td>


                      <td class="px-4 py-3">
                        <div class="relative w-full h-4 bg-gray-200 dark:bg-gray-600 rounded-full overflow-hidden">
                          <!-- Progress Bar -->
                          <div 
                            class="h-full bg-blue-500 dark:bg-blue-400 transition-all duration-300 ease-in-out"
                            :style="{ width: course.completionPercentage + '%' }">
                          </div>
                      
                          <!-- Percentage Display -->
                          <div 
                            class="absolute inset-0 flex items-center justify-center text-sm font-medium"
                            :class="course.completionPercentage > 20 ? 'text-white' : 'text-gray-800'">
                            {{ course.completionPercentage }}%
                          </div>
                        </div>
                      </td>

                      <td class="px-4 py-3">{{ course.unitsCompleted }}/{{ course.totalUnits }}</td>
                      <td class="px-4 py-3">{{ formatTime(course.timeSpent) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="text-center py-10">
                <p class="text-gray-500 dark:text-gray-300">No courses assigned to this user.</p>
              </div>
              
            </div>
          </div>


          <!-- Certificates Tab -->
          <div v-if="activeTab === 'certificates'" class="bg-white shadow-md rounded-lg p-6">
            <h2 class="text-xl font-semibold text-gray-800 mb-4">Certificates</h2>

            <div v-if="loadingCertificates && activeTab === 'certificates'" class="text-center py-10">
              <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else-if="completedCertificates.length" class="overflow-x-auto">
              <table class="w-full text-md text-left text-gray-500">
                <thead>
                  <tr>
                    <th class="px-4 py-3">Course Name</th>
                    <th class="px-4 py-3">Issued At</th>
                    <th class="px-4 py-3">Expires At</th>
                    <th class="px-4 py-3">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="certificate in completedCertificates" :key="certificate.id">
                    <td class="px-4 py-3">{{ certificate.title }}</td>
                    <td class="px-4 py-3">{{ certificate.completed_date }}</td>
                    <td class="px-4 py-3">{{ calculateExpiryDate(certificate.completed_date) }}</td>
                    <td class="px-4 py-3">
                      <button @click="downloadCertificate(certificate)"
                        class="p-2 rounded bg-gray-200 hover:bg-gray-300">
                        Download
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-else class="text-center text-gray-600 py-10">
              <p>No completed certificates available.</p>
            </div>
          </div>

<!-- Activity Tab -->
<div v-if="activeTab === 'activity'" class="bg-white shadow-md rounded-lg p-6">
  <h2 class="text-xl font-semibold text-gray-800 mb-4">User Activity Log</h2>

  <!-- Loading State -->
  <div v-if="loadingActivityLog" class="text-center py-10">
    <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>


  <!-- Activity Log List -->
  <div v-else-if="activityLogs.data.length > 0">
    <ul class="divide-y divide-gray-200">
      <li v-for="log in activityLogs.data" :key="log.id" class="py-4">
        <p class="text-gray-800 font-semibold">
          {{ log.message }}
        </p>
        <p class="text-sm text-gray-500">{{ new Date(log.created_at).toLocaleString() }}</p>
      </li>
    </ul>

    <!-- Pagination Controls -->
    <div class="flex justify-between items-center mt-4">
      <button 
        @click="fetchActivityLogs(activityLogs.paginatorInfo.currentPage - 1)" 
        :disabled="activityLogs.paginatorInfo.currentPage === 1"
        class="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 disabled:opacity-50"
      >
        Previous
      </button>

      <span class="text-gray-700">
        Page {{ activityLogs.paginatorInfo.currentPage }} of {{ activityLogs.paginatorInfo.lastPage }}
      </span>

      <button 
        @click="fetchActivityLogs(activityLogs.paginatorInfo.currentPage + 1)" 
        :disabled="activityLogs.paginatorInfo.currentPage === activityLogs.paginatorInfo.lastPage"
        class="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 disabled:opacity-50"
      >
        Next
      </button>
    </div>
  </div>

  <!-- No Data State -->
  <div v-else class="text-center text-gray-600 py-10">
    <p>No activity logs found.</p>
  </div>
</div>



        </section>
      </div> 
    </main>
    <UnitDetailsModal :visible="showUnitModal" :units="selectedCourseUnits" :courseTitle="selectedCourseTitle"
      @close="closeCourseModal" />
  </div>
</template>

<script>
import TopNav from "@/components/headers/TopNav.vue";
import SideMenu from "@/components/SideMenu.vue";
import StatCard from "@/components/Reports/StatCard.vue";
import axios from 'axios';
import { Chart } from 'chart.js/auto';
import UnitDetailsModal from "@/components/Reports/UnitDetailsModal.vue";

export default {
  components: {
    TopNav,
    SideMenu,
    StatCard,
    UnitDetailsModal
  },
  data() {
    return {
      loading: true,
    loadingCertificates: true,
    progressDataLoading: true,
    loadingActivityLog: true,
    tabs: [
        { id: "overview", label: "Overview" },
        { id: "courses", label: "Courses" },
        { id: "certificates", label: "Certificates" },
        { id: "activity", label: "Activity" },
      ],
      activeTab: "overview",
      showUnitModal: false,
      selectedCourseUnits: [],
      selectedCourseTitle: '',
      searchQuery: "", // Add this to prevent undefined errors
      stats: {
        completionRate: 0, // Initialize with default values
        completedCourses: 0,
        coursesInProgress: 0,
        notStartedCount: 0,
      },
      summary: {
        totalCourses: 0,
        completedCourses: 0,
        completionRate: 0,
      },
      activityLogs: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 1,
          total: 0,
        },
      },
      loadingEnrolledCourses: true,
      user: {
        id: 1,
        first_name: "",
        last_name: "",
        email: "",
        enrolledCourses: [],
      },
      completedCertificates: [],
      courses: [],
      isSidebarOpen: false,
    };
  },
  mounted() {
  this.$nextTick(() => {
    this.initializeCharts();
  });
},
  created() {
    this.fetchUserDetails();
    this.fetchProgressData();
    this.fetchCompletedCertificates();
    this.fetchActivityLogs(1);
  },
  watch: {
  activeTab(newTab) {
    if (newTab === 'overview') {
      this.$nextTick(() => {
        this.initializeCharts();
      });
    }
  },
},
  computed: {
    filteredEnrolledCourses() {
      if (!this.user.enrolledCourses) return [];
      return this.user.enrolledCourses.filter((course) =>
        course.course_title?.toLowerCase().includes(this.searchQuery?.toLowerCase() || "")
      );
    },
  },

  methods: {
    toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
    openCourseModal(course) {
      if (!course) {
        console.error('No course data provided.');
        return;
      }

      // Extract the course title and units
      this.selectedCourseTitle = course.course_title || 'Unknown Course';

      // Ensure `course.units` is an object with the expected structure, even if it’s missing
      this.selectedCourseUnits = course.units || {
        contents: [],
        videos: [],
        audios: [],
        documents: [],
        iframes: [],
        scorms: [],
        assignments: [],
        tests: []
      };

      // If the course has no units at all, log a helpful message
      if (!Object.keys(this.selectedCourseUnits).length) {
        console.warn(`Course "${this.selectedCourseTitle}" has no associated units.`);
      }

      // Open the modal
      this.showUnitModal = true;
    },
    closeCourseModal() {
      this.showUnitModal = false;
      this.selectedCourseUnits = [];
      this.selectedCourseTitle = '';
    },

    calculateExpiryDate(completedDate) {
      if (!completedDate) return 'N/A';
      const completed = new Date(completedDate.split('/').reverse().join('-'));
      completed.setFullYear(completed.getFullYear() + 1);
      return completed.toLocaleDateString('en-GB');
    },
    async fetchProgressData() {
      this.progressDataLoading = true;
      const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;
      const userId = this.$route.params.id; // Extract the user ID from route params

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
          query fetchUserProgress($userId: Int!, $branchUrl: String) {
          UserProgress(userId: $userId, branchUrl: $branchUrl) {
            totalCourses
            completedCourses
            averageCompletionRate
            courses {
              id
              title
              completionPercentage
              unitsCompleted
              totalUnits
              timeSpent
              units {
                contents {
                  id
                  title
                  is_complete
                  time_spent
                }
                videos {
                  id
                  title
                  is_complete
                  time_spent
                }
                audios {
                  id
                  title
                  is_complete
                  time_spent
                }
                documents {
                  id
                  title
                  is_complete
                  time_spent
                }
                iframes {
                  id
                  title
                  is_complete
                  time_spent
                }
                scorms {
                  id
                  title
                  is_complete
                  time_spent
                }
                assignments {
                  id
                  title
                  is_complete
                  time_spent
                }
                tests {
                  id
                  is_complete
                  time_spent
                }
              }
            }
          }
        }
      `,
      variables: { userId: parseInt(userId, 10), branchUrl },

        });

        const data = response.data.data.UserProgress;

        // Update the course data and summary
        this.courses = data.courses.map(course => ({
          ...course,
          completionPercentage: Math.min(course.completionPercentage, 100), 
          units: {
            contents: course.units?.contents || [],
            videos: course.units?.videos || [],
            audios: course.units?.audios || [],
            documents: course.units?.documents || [],
            iframes: course.units?.iframes || [],
            scorms: course.units?.scorms || [],
            assignments: course.units?.assignments || [],
            tests: course.units?.tests || [],
          },
        }));

        this.summary = {
          totalCourses: data.totalCourses,
          completedCourses: data.completedCourses,
          completionRate: Math.round(data.averageCompletionRate),
        };

        this.stats = {
          completionRate: Math.round(data.averageCompletionRate),
          completedCourses: data.completedCourses,
          coursesInProgress: this.courses.filter(course => course.completionPercentage > 0 && course.completionPercentage < 100).length,
          notStartedCount:
            this.summary.totalCourses -
            this.summary.completedCourses -
            this.courses.filter(course => course.completionPercentage > 0 && course.completionPercentage < 100).length,
        };

      } catch (error) {
        console.error('Error fetching progress data:', error);
      } finally {
        this.progressDataLoading = false;

        // Initialize charts after DOM updates
        this.$nextTick(() => {
          this.initializeCharts();
        });
      }
    },

    initializeCharts() {
  const progressCanvas = document.getElementById('progressBarChart');
  const statusCanvas = document.getElementById('statusPieChart');

  // Debugging
  console.log("Progress Canvas Found:", !!progressCanvas);
  console.log("Courses Data:", this.courses);

  if (progressCanvas) {
    const progressChart = progressCanvas.getContext('2d');

    const latestCourses = this.courses.slice(0, 5);
    console.log("Latest Courses for Progress Chart:", latestCourses);

    if (!latestCourses.length) {
      console.warn("No courses available for Progress Chart.");
      return;
    }

    try {
      new Chart(progressChart, {
        type: 'bar',
        data: {
          labels: latestCourses.map(course => course.title),
          datasets: [
            {
              label: 'Completion (%)',
              data: latestCourses.map(course => course.completionPercentage),
              backgroundColor: '#4CAF50',
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: { display: false },
            tooltip: { callbacks: { label: (context) => `${context.raw}%` } },
          },
          scales: {
            x: { title: { display: true, text: 'Courses', color: '#666' } },
            y: { beginAtZero: true, max: 100, title: { display: true, text: 'Completion (%)', color: '#666' } },
          },
        },
      });
      console.log("Progress Chart initialized successfully");
    } catch (error) {
      console.error("Error initializing Progress Chart:", error);
    }
  }

  if (statusCanvas) {
    const statusChart = statusCanvas.getContext('2d');
    const notStartedCount =
      this.summary.totalCourses -
      this.summary.completedCourses -
      this.courses.filter(course => course.completionPercentage > 0 && course.completionPercentage < 100).length;

    new Chart(statusChart, {
      type: 'pie',
      data: {
        labels: ['Completed', 'In Progress', 'Not Started'],
        datasets: [
          {
            data: [
              this.summary.completedCourses,
              this.courses.filter(course => course.completionPercentage > 0 && course.completionPercentage < 100).length,
              notStartedCount > 0 ? notStartedCount : 0,
            ],
            backgroundColor: ['#4CAF50', '#FFC107', '#F44336'],
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          tooltip: { callbacks: { label: (context) => `${context.label}: ${context.raw}` } },
        },
      },
    });
  }
},
    async fetchUserDetails() {
      this.loading = true;
      try {
        const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
        query ($id: ID!, $branchUrl: String) {
          user(id: $id, branchUrl: $branchUrl) {
            id
            first_name
            last_name
            email
            enrolledCourses {
              id
              course_id
              course_title
              date_assigned
              progress
              unitsCompleted
              totalUnits
              timeSpent
              units {
                contents {
                  id
                  title
                  is_complete
                  time_spent
                }
                videos {
                  id
                  title
                  is_complete
                  time_spent
                }
                audios {
                  id
                  title
                  is_complete
                  time_spent
                }
                documents {
                  id
                  title
                  is_complete
                  time_spent
                }
                iframes {
                  id
                  title
                  is_complete
                  time_spent
                }
                scorms {
                  id
                  title
                  is_complete
                  time_spent
                }
                assignments {
                  id
                  title
                  is_complete
                  time_spent
                }
                tests {
                  id
                  is_complete
                  time_spent
                }
              }
            }
          }
        }
      `,
          variables: { 
            id: this.$route.params.id,
            branchUrl: branchUrl,
          },
        });

        const userData = response.data.data.user;

        // Map and include units for each enrolledCourse
        this.user = {
          id: userData.id,
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          enrolledCourses: userData.enrolledCourses.map((course) => ({
            id: course.course_id,
            course_title: course.course_title,
            date_assigned: course.date_assigned,
            progress: course.progress,
            completionPercentage: course.progress,
            unitsCompleted: course.unitsCompleted,
            totalUnits: course.totalUnits,
            timeSpent: course.timeSpent,
            units: course.units || {
              contents: [],
              videos: [],
              audios: [],
              documents: [],
              iframes: [],
              scorms: [],
              assignments: [],
              tests: []
            }
          }))
        };

        console.log(this.user)

      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchActivityLogs(page) {
      this.loadingActivityLog = true;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
      query: `
      query GetUserActivityLogs($id: ID!, $page: Int!, $limit: Int!, $branchUrl: String) {
        userActivityLog(id: $id, page: $page, limit: $limit, branchUrl: $branchUrl) {
          data {
            id
            message
            created_at
          }
          paginatorInfo { 
            currentPage
            lastPage
            total
          }
        }
      }`,
      variables: { id: this.$route.params.id, page, limit: 10, branchUrl: this.$store.state.branch?.url ?? null },
    });

    this.activityLogs = response.data.data.userActivityLog;
  } catch (error) {
    this.loadingActivityLog = false;
    console.error("Error fetching activity logs:", error);
  } finally {
    this.loadingActivityLog = false;
    this.loadingActivityLog = false;
  }
},
  parseMetadata(metadata) {
    try {
      return JSON.parse(metadata); // Convert JSON string to an object
    } catch (error) {
      console.error("Error parsing metadata:", error);
      return {}; // Return an empty object if parsing fails
    }
  },
    async fetchCompletedCertificates() {
      const userId = parseInt(this.$route.params.id)
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
        query fetchCompletedCourses($userId: Int, $branchUrl: String) {
          CompletedCourses(userId: $userId, branchUrl: $branchUrl) {
                id
                title
                is_complete
                completed_date
                certificate_type
              }
            }
          `,
          variables: {
        userId,
        branchUrl: this.$store.state.branch ? this.$store.state.branch.url : null,
      },
        });
        this.completedCertificates = response.data.data.CompletedCourses || [];
      } catch (error) {
        console.error("Error fetching certificates:", error);
      } finally {
        this.loadingCertificates = false;
      }
    },
    async downloadCertificate(course) {
      try {
        // Validate required course data
        if (!course.title || !course.completed_date) {
          throw new Error("Missing course title or completion date.");
        }

        // Format dates properly
        const issuedDate = new Date(course.completed_date.split('/').reverse().join('-')).toISOString().split('T')[0];
        const expiryDate = new Date(this.calculateExpiryDate(course.completed_date).split('/').reverse().join('-')).toISOString().split('T')[0];
        const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

        // GraphQL request
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
        mutation GenerateCertificate($input: GenerateCertificateInput!, $branchUrl: String) {
          GenerateCertificate(input: $input, branchUrl: $branchUrl) {
            message
            certificate_url
          }
        }
      `,
          variables: {
            input: {
              user_name: `${this.user.first_name} ${this.user.last_name}`,
              course_name: course.title,
              issued_date: issuedDate,
              expiry_date: expiryDate,
              certificate_type: course.certificate_type || "Default",

            },
            branchUrl,
          },
        });

        const { certificate_url } = response.data.data.GenerateCertificate;

        if (!certificate_url) {
          throw new Error("Certificate URL is missing in the response.");
        }

        // Download certificate
        const link = document.createElement('a');
        link.href = certificate_url;
        link.download = `${course.title}-certificate.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading certificate:", error);

        // Optional: Show error notification to the user
        this.$toast.error("Failed to download the certificate. Please try again.");
      }
    },
    statusMarkerClass(completionPercentage) {
      if (completionPercentage === 0) return 'bg-gray-400';
      if (completionPercentage < 100) return 'bg-yellow-500';
      return 'bg-green-500';
    },
    formatTime(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = Math.floor(seconds % 60);
      return `${hrs}h ${mins}m ${secs}s`;
    },

    formatDate(date) {
      if (!date) return "N/A";
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style>
.main {
  background-color: #f8f9fa;
}
</style>