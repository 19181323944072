<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
    <TopNav @toggle-sidebar="toggleSidebar"  />

    <!-- Sidebar -->
    <CourseSideMenu :courseTitle="courseData.title" :units="courseData.units" @unit-selected="handleUnitSelected" :courseId="courseId" :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />

    <main class="flex items-center justify-center p-4 md:ml-96 h-auto pt-20">
      <div class="w-full max-w-5xl mt-3">

        <!-- Conditionally render selected unit component -->
        <component 
          :is="getComponentName(selectedUnit?.__typename)" 
          v-if="selectedUnit" 
          :unitData="selectedUnit"
          :courseId="courseId" 
          :unitId="selectedUnit.unit_order" 
          @submit-response="handleUnitResponse"
          @next-unit="goToNextUnit"
          @set-answer="trackResponse"
          :savedResponses="userResponses[selectedUnit.id] || {}"
          @complete-unit="handleUnitComplete"
        />
        
        <!-- Conditionally render TestSlider if the unit has tests but is not TestUnit -->
        <h2 class="text-xl font-semibold mt-10"
          v-if="selectedUnit?.tests && selectedUnit.tests.length > 0 && getComponentName(selectedUnit?.__typename) !== 'TestUnit'">
          Please complete the tests below to continue to the next unit
        </h2>
        <TestSlider
          v-if="selectedUnit?.tests && selectedUnit.tests.length > 0 && getComponentName(selectedUnit?.__typename) !== 'TestUnit'"
          :tests="selectedUnit.tests" :savedResponses="userResponses[selectedUnit.id] || {}"
          @test-answered="trackResponse" ref="testSlider" class="mt-6" />
          
        <!-- Timer display for units with time limits -->
        <div v-if="showTimer && timeLeft > 0" class="mt-4 text-lg text-gray-700">
          You need to stay on this unit for {{ timeLeft }} seconds before continuing.
        </div>

        <!-- Error message section -->
        <div v-if="errorMessage" class="text-red-500 font-bold mt-4">
          {{ errorMessage }}
        </div>

        <!-- Navigation Buttons -->
        <div class="flex justify-between mt-6">
          <button v-if="hasPreviousUnit" class="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-500"
            @click="goToPreviousUnit">
            Back
          </button>

          <!-- Conditionally render Next button when not on TestUnit -->
          <button v-if="getComponentName(selectedUnit?.__typename) !== 'TestUnit'"
            class="ml-auto bg-primary-700 text-white px-4 py-2 rounded-md hover:bg-primary-800"
            @click="checkAnswersBeforeNext">
            Next
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import CourseSideMenu from '@/components/CourseSideMenu.vue';
import ContentUnit from '@/components/learner/units/ContentUnit.vue';
import VideoUnit from '@/components/learner/units/VideoUnit.vue';
import AudioUnit from '@/components/learner/units/AudioUnit.vue';
import DocumentUnit from '@/components/learner/units/DocumentUnit.vue';
import iFrameUnit from '@/components/learner/units/iFrameUnit.vue';
import ScormUnit from '@/components/learner/units/ScormUnit.vue';
import TestUnit from '@/components/learner/units/TestUnit.vue';
import AssignmentUnit from '@/components/learner/units/AssignmentUnit.vue';
import TestSlider from '@/components/learner/units/TestSlider.vue';

export default {
  data() {
    return {
      selectedUnit: null, // Track the currently selected unit
      courseData: {
        title: '',  // Initialize course title
        units: {},   // Initialize units as an empty object
      },
      courseId: this.$route.params.course, // Get course ID from route
      userResponses: {}, // Track responses for the current unit
      allQuestionsAnswered: false, // Track if all questions are answered correctly
      errorMessage: '', // Add an error message property
      timeLeft: 0, // Timer countdown value
      timer: null, // Reference to the unified timer
      completedUnits: {}, // Track which units the user has already waited for
      showTimer: false, // Control timer display
      unitTimeSpent: 0, // Track the time spent on the unit
      isSidebarOpen: false,
    };
  },

  computed: {
    allUnits() {
      return Object.values(this.courseData.units).flat().sort((a, b) => a.unit_order - b.unit_order);
    },
    hasPreviousUnit() {
      if (!this.selectedUnit) return false;
      return this.selectedUnit.unit_order > 1;
    },
    hasNextUnit() {
      if (!this.selectedUnit) return false;
      return this.selectedUnit.unit_order < this.allUnits.length;
    },
    isLastUnit() {
      return this.selectedUnit && this.selectedUnit.unit_order === this.allUnits.length;
    },
    canProceedToNextUnit() {
      if (this.selectedUnit?.tests && this.selectedUnit.tests.length > 0) {
        const testSlider = this.$refs.testSlider;
        if (testSlider) {
          const answers = testSlider.getAllAnswers() || [];
          return Array.isArray(answers) && answers.every(answer => answer?.isCorrect !== undefined && answer.isCorrect);
        }
        return false;
      } else if (this.selectedUnit?.time_limit) {
        return this.timeLeft === 0 || this.completedUnits[this.selectedUnit.id]; // If the user already waited or time is up
      }
      return true; // If no tests or time limit, allow proceeding
    }
  },

  created() {
    this.fetchCourseData(); // Fetch course data and then handle unit selection
  },

  watch: {
    'courseData.title': function (newTitle) {
      if (newTitle) {
        document.title = `${newTitle} | Jam LMS`; // Update page title
      }
    },
    // Watch for changes in selectedUnit
    selectedUnit: {
      immediate: true,
      handler(newUnit) {
        // Clear error message when switching units
        this.errorMessage = '';

        // Initialize or continue time tracking for the new unit
        if (this.userResponses[newUnit?.id]?.timeSpent) {
          this.unitTimeSpent = this.userResponses[newUnit.id].timeSpent;
        } else {
          this.unitTimeSpent = 0;
        }

        this.startTimerOrTrackTime(newUnit);
      }
    },
    
    // Watch for changes in the route params (unit ID)
    '$route.params.unit_order': {
    immediate: true,
    handler(newOrder) {
      const parsedOrder = parseInt(newOrder, 10);
      this.selectedUnit = this.allUnits.find(unit => unit.unit_order === parsedOrder) || this.allUnits[0];
    }
  }
  },

  components: {
    TopNav,
    CourseSideMenu,
    ContentUnit,
    VideoUnit,
    AudioUnit,
    DocumentUnit,
    iFrameUnit,
    ScormUnit,
    TestUnit,
    AssignmentUnit,
    TestSlider,
  },

  methods: {
    toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
    startTimerOrTrackTime(newUnit) {
      // If the unit has a time limit but the user hasn't completed waiting
      if (newUnit?.time_limit && !this.completedUnits[newUnit.id]) {
        this.timeLeft = newUnit.time_limit - this.unitTimeSpent;
        this.showTimer = true; // Show the timer

        // If the timer is already up
        if (this.timeLeft <= 0) {
          this.completedUnits[newUnit.id] = true;
          this.showTimer = false; // Hide the timer
        } else {
          this.startUnifiedTimer();
        }
      } else {
        this.showTimer = false; // Hide timer for non-timed units
        this.startUnifiedTimer(); // Track regular unit time
      }
    },

    startUnifiedTimer() {
      if (this.timer) {
        clearInterval(this.timer); // Clear any existing timer
      }

      this.timer = setInterval(() => {
        this.unitTimeSpent++;

        if (this.timeLeft > 0) {
          this.timeLeft--; // Countdown for time-limited units
          if (this.timeLeft === 0) {
            this.completedUnits[this.selectedUnit.id] = true; // Mark the unit as completed
            this.showTimer = false; // Hide the timer when it's done
          }
        }
        
        this.updateUnitTimeSpent(); // Update the time spent on the unit
      }, 1000);
    },

    updateUnitTimeSpent() {
      if (this.selectedUnit && this.selectedUnit.id) {
        const unitId = this.selectedUnit.id;
        if (!this.userResponses[unitId]) {
          this.userResponses[unitId] = {};
        }
        this.userResponses[unitId].timeSpent = this.unitTimeSpent;
      } else {
        console.warn('No selected unit or invalid unit ID');
      }
    },

    async fetchCourseData() {
      const courseId = parseInt(this.courseId, 10);
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
        query: `
query GetCourseWithPrerequisites($id: Int!) {
    course(id: $id) {
        id
        title
        learning_path
        prerequisites {    
            id
            title
            is_complete
        }
        userResponses {
            unit_id
            input_type
            input_key
            response
            file_path
        }
        units {
            contents {
                id
                title
                unit_order
                content
                completion_option
                time_limit
                is_complete
                tests {
                    id
                    question
                    type
                    pairs {
                        left
                        right
                    }
                    options {
                        text
                        checked
                    }
                    rules {
                        condition
                        word
                        points
                    }
                    required_points
                }
                __typename
            }
            videos {
                id
                title
                unit_order
                video_path
                youtube_link
                completion_option
                time_limit
                is_complete
                content
                tests {
                    id
                    question
                    type
                    pairs {
                        left
                        right
                    }
                    options {
                        text
                        checked
                    }
                    rules {
                        condition
                        word
                        points
                    }
                    required_points
                }
                __typename
            }
            audios {
                id
                title
                unit_order
                audio_path
                completion_option
                time_limit
                is_complete
                content
                tests {
                    id
                    question
                    type
                    pairs {
                        left
                        right
                    }
                    options {
                        text
                        checked
                    }
                    rules {
                        condition
                        word
                        points
                    }
                    required_points
                }
                __typename
            }
            documents {
                id
                title
                unit_order
                file_path
                completion_option
                time_limit
                is_complete
                description
                __typename
            }
            iframes {
                id
                title
                unit_order
                url
                completion_option
                time_limit
                is_complete
                content
                tests {
                    id
                    question
                    type
                    pairs {
                        left
                        right
                    }
                    options {
                        text
                        checked
                    }
                    rules {
                        condition
                        word
                        points
                    }
                    required_points
                }
                __typename
            }
            scorms {
                id
                title
                unit_order
                scorm_path
                scorm_id
                is_complete
                scorm {
                    id
                    title
                    version
                    entry_url
                    uuid
                    scos {
                        id
                        title
                        uuid
                        entry_url
                    }
                }
                __typename
            }
            tests {
                id
                unit_order
                is_complete
                tests {
                    id
                    question
                    type
                    pairs {
                        left
                        right
                    }
                    options {
                        text
                        checked
                    }
                    rules {
                        condition
                        word
                        points
                    }
                    required_points
                }
                __typename
            }
            assignments {
                id
                title
                unit_order
                content
                completion_option
                is_complete
                __typename
            }
        }
    }
}


        `,
        variables: {
        id: courseId,
      }
    });
      this.courseData = response.data.data.course;
      document.title = `${this.courseData.title} | Jam LMS`; 

      // Map the user responses
      if (this.courseData.userResponses.length > 0) {
        this.mapUserResponses(this.courseData.userResponses);
      }

      const prerequisites = this.courseData.prerequisites || [];
      const incompletePrerequisites = prerequisites.length > 0 && prerequisites.some(course => !course.is_complete);

      // Now select the appropriate unit from the route or fall back to the first one
      if (incompletePrerequisites) {
        this.$router.push({
        name: 'ViewCourse',
        params: { id: this.courseId },
        query: { error: 'Please complete all prerequisite courses before starting this course.' }
      });
    } else {
      const unitOrder = parseInt(this.$route.params.unit_order, 10);
      if (unitOrder) {
        this.selectedUnit = this.allUnits.find(unit => unit.unit_order === unitOrder);
      } else {
        this.navigateToFirstIncompleteUnit();
      }
    }
    },
    handleUnitSelectedById(unitId) {
    const parsedUnitId = String(unitId);
    
    // Find the unit by id
    const selectedUnit = this.allUnits.find(unit => String(unit.id) === parsedUnitId);

    if (selectedUnit) {
      // If the unit is found, set it as the selected unit
      this.selectedUnit = selectedUnit;
    } else {
      // If the unit does not exist, navigate to the first incomplete unit
      console.warn(`Unit with ID ${unitId} not found, navigating to first incomplete unit`);
      this.navigateToFirstIncompleteUnit();
    }
  },
  navigateToFirstIncompleteUnit() {
  // Combine all units into one array
  const allUnits = Object.values(this.courseData.units)
    .flat()
    .sort((a, b) => a.unit_order - b.unit_order);

  // Find the first incomplete unit
  const firstIncompleteUnit = allUnits.find(unit => !unit.is_complete) || allUnits[0];

  if (firstIncompleteUnit) {
    this.$router.push({
      name: 'course',
      params: {
        course: this.courseId,
        unit_order: firstIncompleteUnit.unit_order, // Use unit_order instead of id
      },
    });
  } else {
    console.warn("No units found in navigateToFirstIncompleteUnit");
  }
},

    mapUserResponses(userResponses) {
  userResponses.forEach(response => {
    const { unit_id, input_key, response: savedResponse } = response;

    // Parse the saved response JSON
    const parsedResponse = JSON.parse(savedResponse);

    // Initialize the userResponses object for the unit if it doesn't exist
    if (!this.userResponses[unit_id]) {
      this.userResponses[unit_id] = {};
    }

    // Store the timeSpent directly in the unit if it exists in the response
    if (parsedResponse.timeSpent) {
      this.userResponses[unit_id].timeSpent = parsedResponse.timeSpent;
      delete parsedResponse.timeSpent; // Remove it from the response object if it's included
    }

    // Store the parsed response directly under the unit_id and test_id (no nesting under `answers`)
    this.userResponses[unit_id][input_key] = parsedResponse;
  });
},

trackResponse(testId, answerObject) {
  const unitId = this.selectedUnit.id;

  // Ensure userResponses for the selected unit is initialized
  if (!this.userResponses[unitId]) {
    this.userResponses[unitId] = {};  // Initialize the unit object
  }
  console.log(answerObject);

  // Store the time spent at the unit level
  this.userResponses[unitId].timeSpent = this.unitTimeSpent;

  // Store the response for the specific test or unit directly without nesting
  this.userResponses[unitId][testId] = answerObject;

  // Call mutation to store the response in the database
  this.storeUnitResponse(testId, answerObject, unitId);
},

    async storeUnitResponse(testId, answerObject, unitId) {
      try {
        const mutation = `
          mutation StoreUnitResponse($input: UnitResponseInput!) {
            storeUnitResponse(input: $input) {
              id
              response
            }
          }
        `;

        const response = {
          ...answerObject,
          timeSpent: this.userResponses[unitId].timeSpent // Include timeSpent in the response
        };

        const variables = {
          input: {
            course_id: parseInt(this.courseId, 10),  // Convert course_id to integer
            unit_id: parseInt(unitId, 10),  // Ensure unit_id is a string
            unit_type: this.selectedUnit.__typename,
            input_type: answerObject.type || "Completion",  // Pass input_type to mutation
            input_key: String(testId),  // The testId or key for each question or 'isCorrect' for non-tests
            response: JSON.stringify(response) // Store the response as a JSON string
          }
        };

        // Make the request to the GraphQL API
        await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: mutation,
          variables
        });

      } catch (error) {
        console.error("Error storing unit response:", error);
      }
    },

    checkAnswersBeforeNext() {
  // Check if the current unit has tests
  if (this.selectedUnit?.tests && this.selectedUnit.tests.length > 0) {
    const testAnswers = this.$refs.testSlider?.getAllAnswers();
    const allAnswersCorrect = this.checkIfAllAnswersCorrect(testAnswers);

    if (allAnswersCorrect) {
      this.goToNextUnit(); // Proceed to the next unit after marking it as complete
    } else {
      this.errorMessage = 'You answered one of more questions incorrectly. Please change your answers then try clicking Next again.'; // Set error for incorrect answers
    }
  }
  // Check if the current unit has a time limit and it hasn't been completed
  else if (this.selectedUnit?.time_limit && !this.completedUnits[this.selectedUnit.id]) {
    this.errorMessage = `You must wait until the time is up before proceeding.`; // Set error for time limit
  } 
  // If no tests and no time limit, allow the user to proceed
  else {
    this.goToNextUnit(); // Proceed to the next unit after marking it as complete
    this.errorMessage = ''; // Clear any error message
  }
},



    checkIfAllAnswersCorrect(testAnswers) {
      return testAnswers.every(answer => answer && answer.isCorrect);
    },
    mapTypenameToComponent(typename) {
    switch (typename) {
      case 'Content':
        return 'contents';
      case 'Video':
        return 'videos';
      case 'AudioUnit':
        return 'audios';
      case 'DocumentUnit':
        return 'documents';
      case 'iFrameUnitType':
        return 'iframes';
      case 'ScormUnitType':
        return 'scorms';
      case 'TestUnitType':
        return 'tests';
      case 'AssignmentUnitType':
        return 'assignments';
      default:
        console.warn(`Unknown typename: ${typename}`);
        return null;
    }
  },
    getComponentName(type) {

  let componentName = null;
  switch (type) {
    case 'Content':
      componentName = 'ContentUnit';
      break;
    case 'Video':
      componentName = 'VideoUnit';
      break;
    case 'AudioUnit':
      componentName = 'AudioUnit';
      break;
    case 'DocumentUnit':
      componentName = 'DocumentUnit';
      break;
    case 'iFrameUnitType':
      componentName = 'iFrameUnit';
      break;
    case 'ScormUnitType':
      componentName = 'ScormUnit';
      break;
    case 'TestUnitType':
      componentName = 'TestUnit';
      break;
    case 'AssignmentUnitType':
      componentName = 'AssignmentUnit';
      break;
    default:
      console.warn(`No component mapped for type: ${type}`);
  }
  return componentName;
},

async markUnitComplete(unitId) {
  const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

  try {
    const mutation = `
      mutation MarkUnitComplete($input: MarkUnitCompleteInput!, $branchUrl: String) {
        markUnitComplete(input: $input, branchUrl: $branchUrl) {
          id
          is_complete
          unit_id
          unit_type
        }
      }
    `;
    const variables = {
      input: {
        course_id: parseInt(this.courseId, 10),
        unit_id: parseInt(unitId, 10),
        unit_type: this.selectedUnit.__typename,
      },
      branchUrl,
    };


    const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
      query: mutation,
      variables,
    });


    if (response.data.data && response.data.data.markUnitComplete) {
      const updatedCompletion = response.data.data.markUnitComplete;
      const componentType = this.mapTypenameToComponent(updatedCompletion.unit_type);

      // Update courseData with the new is_complete status
      if (componentType && this.courseData.units[componentType]) {
        const unit = this.courseData.units[componentType].find(
          u => String(u.id) === String(updatedCompletion.unit_id) && u.__typename === updatedCompletion.unit_type
        );

        if (unit) {
          unit.is_complete = updatedCompletion.is_complete;
        }
      } else {
        console.warn(`No units found for component type ${componentType}`);
      }

      // Optionally, update UI progress if you're tracking course progress
      this.updateProgress();
    } else {
      console.error(`Failed to mark unit ${unitId} as complete, response was:`, response.data);
    }
  } catch (error) {
    console.error('Error marking unit as complete:', error);
  }
},

updateProgress() {
  const totalUnits = Object.values(this.courseData.units).flat().length;
  const completedUnits = Object.values(this.courseData.units)
    .flat()
    .filter(unit => unit.is_complete).length;

  this.courseProgress = totalUnits ? (completedUnits / totalUnits) * 100 : 0;
},

goToNextUnit() {
  const currentIndex = this.selectedUnit.unit_order - 1;
  const nextUnit = this.allUnits[currentIndex + 1];

  // If there's a next unit
  if (nextUnit) {
    // If the current unit has no tests or just a time limit, send the response to trackResponse
    if (!this.selectedUnit.tests?.length && this.selectedUnit.time_limit) {
      // If the unit has a time limit but no tests, track the time spent after the time is up
      const answerObject = {
        type: 'CompletionWithNoTest',
        isCorrect: true,
      };

      // Call trackResponse for non-test units
      this.trackResponse(this.selectedUnit.unit_order, answerObject); // Use unit_order instead of id

      // Mark the current unit as complete before proceeding to the next one
      this.markUnitComplete(this.selectedUnit.id); // Use unit_order
    } else if (this.selectedUnit.tests?.length > 0) {
      // If the current unit has tests, ensure they are completed
      const allAnswersCorrect = this.checkIfAllAnswersCorrect(this.$refs.testSlider?.getAllAnswers());

      if (allAnswersCorrect) {
        // Track response and mark the unit as complete
        this.markUnitComplete(this.selectedUnit.id); // Use unit_order
      } else {
        this.errorMessage = 'Please complete all tests before proceeding.';
        return; // Prevent navigating to the next unit if tests are not completed
      }
    } else {
      // Mark the current unit as complete if it's not a test unit or time-limited
      this.markUnitComplete(this.selectedUnit.id); // Use unit_order
    }

    // Navigate to the next unit
    this.selectedUnit = nextUnit;

    // Update the route to reflect the new unit
    this.$router.push({
      name: 'course',
      params: {
        course: this.courseId,
        unit_order: nextUnit.unit_order, // Use unit_order in the URL
      },
    });
  } else {
    // If there's no next unit, mark the current unit as complete
    this.markUnitComplete(this.selectedUnit.id); // Use unit_order

    // Redirect to the ViewCourse page
    this.$router.push({ name: 'ViewCourse', params: { id: this.courseId } });
  }
},
goToPreviousUnit() {
  const currentIndex = this.selectedUnit.unit_order - 1;
  const previousUnit = this.allUnits[currentIndex - 1];

  if (previousUnit) {
    this.selectedUnit = previousUnit;

    // Update the route to reflect the new unit
    this.$router.push({
      name: 'course',
      params: {
        course: this.courseId,
        unit_order: previousUnit.unit_order, // Use unit_order instead of id
      },
    });
  }
},
    async handleUnitComplete() {
      if (this.isLastUnit) {
        this.$router.push({ name: 'ViewCourse', params: { course: this.courseId } });
      } else {
        this.goToNextUnit();
      }
    },

    async handleUnitResponse(responseData) {
      try {
        const mutation = `
          mutation StoreUnitResponse(
            $course_id: Int!,
            $unit_id: Int!,
            $unit_type: String!,
            $input_type: String!,
            $response: String
          ) {
            storeUnitResponse(
              course_id: $course_id,
              unit_id: $unit_id,
              unit_type: $unit_type,
              input_type: $input_type,
              response: $response
            ) {
              id
              response
            }
          }
        `;

        const variables = {
          course_id: parseInt(this.courseId), // Ensure courseId is an integer
          unit_id: parseInt(this.selectedUnit.id), // Ensure unitId is an integer
          unit_type: this.selectedUnit.__typename,
          input_type: responseData.input_type,
          response: responseData.response,
        };

        await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, { query: mutation, variables });
        alert('Response submitted successfully');
      } catch (error) {
        console.error('Error submitting response:', error);
        alert('Failed to submit response');
      }
    },

    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.timeLeft = 0;
      this.showTimer = false; // Hide the timer by default
    },

    beforeDestroy() {
      this.clearTimer(); // Ensure timer is cleared when component is destroyed
    }
  },
};
</script>
