<template>
    <div class="p-6">
      <h2 class="text-2xl font-bold mb-6">Video Format Compatibility Test</h2>
      <VideoUnit :unitData="videoMp4" :courseId="1" :unitId="1" />
      <VideoUnit :unitData="videoMov" :courseId="1" :unitId="2" class="mt-10" />
      <VideoUnit :unitData="videoMov2" :courseId="1" :unitId="2" class="mt-10" />
    </div>
  </template>
  
  <script>
  import VideoUnit from '@/components/learner/units/VideoUnit.vue';
  
  export default {
    components: { VideoUnit },
    data() {
      return {
        videoMp4: {
          title: 'MP4 Video Example - KS2 Simulation City (Session 1 - Workshop Introduction)',
          video_path: 'https://api.toradigital.co.uk/storage/videos/WpjGhVrVIBBqrtVV5HGvy8EvEUrRPVeFj32fjR02.mp4',
          content: 'This is an MP4 video.',
        },
        videoMov: {
          title: 'MOV Video Example - School Radio Show (lesson 3)',
          video_path: 'https://api.toradigital.co.uk/storage/videos/ZnnmefJvVx8OLnngwkthb0V7JJTiGOPoBfJ4Y68u.mov',
          content: 'This is a MOV video.',
        },
        videoMov2: {
          title: 'MOV Video 2 Example - School Radio Show (lesson 1)',
          video_path: 'https://api.toradigital.co.uk/storage/videos/kpZqXGPZuqh7fILlgmg6fItpRHCzBUWkhTS3QyOU.mov',
          content: 'This is a MOV video.',
        }
      };
    }
  };
  </script>
  