<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
    <TopNav @toggle-sidebar="toggleSidebar" />
    <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />

    <main class="p-6 md:ml-64 h-auto pt-20 flex justify-center">
      <section class="w-full max-w-lg dark:bg-gray-800 rounded-lg p-8">
        <div class="mb-6 flex justify-between items-center">
          <h2 class="text-3xl font-semibold text-gray-800 dark:text-gray-100">Create A New Category</h2>
          <router-link to="/categories"
            class="mt-4 ml-4 flex items-center text-primary-700 dark:text-primary-300 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            <span class="ml-2">Back</span>
          </router-link>
        </div>

        <form @submit.prevent="createCategory">
          <!-- Error Messages -->
          <div v-if="errors.length" class="mb-4 bg-red-100 text-red-800 p-3 rounded">
            <ul>
              <li v-for="(error, index) in errors" :key="index" class="text-sm">{{ error }}</li>
            </ul>
          </div>

          <!-- Category Name -->
          <div class="mb-4">
            <label for="name" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Category
              Name</label>
            <input type="text" id="name" v-model="category.name"
              class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
              required />
          </div>

          <!-- Image Upload -->
          <div class="mb-4">
            <label class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Category Image</label>
            <input type="file" @change="handleImageUpload" accept="image/*" />
            <div v-if="category.image">
              <img :src="imagePreview" alt="Category Image" class="mt-2 w-32 h-32 object-cover rounded-md">
            </div>
          </div>


          <!-- Parent Category -->
          <div class="mb-4">
            <label for="parentCategory" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Parent
              Category (Optional)</label>
            <select id="parentCategory" v-model="category.parent_id"
              class="w-full p-3 border rounded-lg dark:bg-gray-700 dark:text-white border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500">
              <option value="">None (Top-Level Category)</option>
              <option v-for="parent in categories" :key="parent.id" :value="parent.id">
                {{ parent.name }}
              </option>
            </select>
          </div>

          <!-- Branch Selection -->
          <div class="mb-4">
            <label class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Assign to Branches</label>
            <vue-multiselect v-model="category.branch_ids" :options="branches" :multiple="true" :close-on-select="false"
              :clear-on-select="false" :preserve-search="true" :taggable="true" placeholder="Select Branches"
              label="name" track-by="id" />
          </div>

          <div class="mb-4">
            <label class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Assign to Groups</label>
            <vue-multiselect v-model="category.group_ids" :options="groups" :multiple="true"
              :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Groups"
              label="name" track-by="id" />
          </div>

          <!-- Submit Button -->
          <div class="mt-6">
            <button type="submit" :disabled="isSubmitting"
              class="w-full !bg-secondary-600 text-white font-semibold py-3 rounded-lg hover:!bg-secondary-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 flex items-center justify-center">
              <svg v-if="isSubmitting" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
              </svg>
              {{ isSubmitting ? 'Creating...' : 'Create Category' }}
            </button>
          </div>
        </form>
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/SideMenu.vue';
import VueMultiselect from 'vue-multiselect';

export default {
  components: {
    TopNav,
    SideMenu,
    VueMultiselect
  },
  data() {
    return {
      category: {
        name: '',
        parent_id: '',
        branch_ids: [],
        group_ids: [],
        image: null
      },
      categories: [],
      branches: [],
      groups: [],
      isSubmitting: false,
      errors: [],
      imagePreview: null,
      isSidebarOpen: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    async fetchBranches() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
        query GetBranches {
          branches {
            id
            name
          }
        }
      `,
        });

        this.branches = response.data.data.branches || [];
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    },
    async fetchGroups() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `query GetGroups { groups { id name } }`
        });

        this.groups = response.data.data.groups || [];
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    },
    async fetchCategories() {
      try {
        const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
              query GetCategories($branchUrl: String) {
                categories(branchUrl: $branchUrl) {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
              }
            `,
          variables: { branchUrl: branchUrl || null },
        });

        this.categories = response.data.data.categories || [];
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.category.image = file;
        this.imagePreview = URL.createObjectURL(file);
      }
    },
    async createCategory() {
      this.isSubmitting = true;
      this.errors = [];
      const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

      const formData = new FormData();

      // Ensure branch_ids is always an array before mapping
      const branchIds = Array.isArray(this.category.branch_ids)
        ? this.category.branch_ids.map(branch => parseInt(branch.id))
        : [];

      // Construct GraphQL variables
      const variables = {
        name: this.category.name,
        parent_id: this.category.parent_id ? parseInt(this.category.parent_id) : null,
        branch_ids: branchIds,
        group_ids: this.category.group_ids.map(group => parseInt(group.id)),
        branchUrl
      };

      let hasNewImage = false;

      // Handle Image Logic
      if (this.category.image === null) {
        variables.image = null;
      } else if (this.category.image instanceof File) {
        variables.image = null;
        hasNewImage = true;
      }

      // Append the GraphQL query to FormData
      formData.append("operations", JSON.stringify({
        query: `
      mutation CreateCategory($name: String!, $parent_id: Int, $branch_ids: [Int], $group_ids: [Int], $image: Upload, $branchUrl: String) {
        CreateCategory(name: $name, parent_id: $parent_id, branch_ids: $branch_ids, group_ids: $group_ids, image: $image, branchUrl: $branchUrl) {
          id
          name
          image
        }
      }
    `,
        variables,
      }));

      // If an image is being uploaded, attach it properly
      if (hasNewImage) {
        formData.append("map", JSON.stringify({ "1": ["variables.image"] }));
        formData.append("1", this.category.image);
      } else {
        // ✅ Add empty map if no image is uploaded to prevent missing parameter error
        formData.append("map", JSON.stringify({}));
      }

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (response.data.errors) {
          this.errors = response.data.errors.map(err => err.message);
        } else {
          this.$router.push('/categories');
        }
      } catch (error) {
        console.error("Error creating category:", error);
        this.errors.push("An error occurred while creating the category.");
      } finally {
        this.isSubmitting = false;
      }
    }





  },
  created() {
    this.fetchCategories();
    this.fetchBranches();
    this.fetchGroups();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
