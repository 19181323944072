<template>
  <div>
    <h2 class="text-2xl font-bold mb-4">{{ unitData.title }}</h2>

    <p class="mt-10 mb-16 text-lg" v-html="unitData.content"  v-if="unitData.content"></p>

    
    <!-- iFrame for displaying the content -->
    <iframe 
      :src="computedIframeUrl" 
      class="w-full rounded-lg shadow-md" 
      frameborder="0"
      style="height:900px;"
      allowfullscreen
    ></iframe>
  
  </div>
</template>

<script>
export default {
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    courseId: {
      type: [String, Number],
      required: true,
    },
    unitId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
  computedIframeUrl() {
    if (!this.unitData.url) return '';

    const apiBaseUrl = process.env.VUE_APP_API_URL || 'https://jamlms.co.uk'; 

    // If HTTPS, load directly
    if (this.unitData.url.startsWith('https://')) {
      return this.unitData.url;
    }

    // If HTTP, use Laravel API Proxy
    const proxiedUrl = `${apiBaseUrl}/api/proxy?url=${encodeURIComponent(this.unitData.url)}`;
    return proxiedUrl;
  }
}


};
</script>

<style scoped>
.iframe-container {
  position: relative;
  padding-bottom: 56.25%; /* Aspect ratio: 16:9 */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
