<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
      <TopNav @toggle-sidebar="toggleSidebar"  />
      <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />
  
      <main class="p-6 md:ml-64 h-auto pt-20 flex justify-center">
        <section class="w-full max-w-lg dark:bg-gray-800 rounded-lg p-8">
          <div class="mb-6 flex justify-between items-center">
            <h2 class="text-3xl font-semibold text-gray-800 dark:text-gray-100">Edit Category</h2>
            <router-link to="/categories" class="mt-4 ml-4 flex items-center text-primary-700 dark:text-primary-300 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              <span class="ml-2">Back</span>
            </router-link>
          </div>

          <div v-if="loadingCat || loadingParCat" class="text-center py-10">
              <svg aria-hidden="true"
                class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
  
          <form @submit.prevent="updateCategory" v-else>
            <!-- Error Messages -->
            <div v-if="errors.length" class="mb-4 bg-red-100 text-red-800 p-3 rounded">
              <ul>
                <li v-for="(error, index) in errors" :key="index" class="text-sm">{{ error }}</li>
              </ul>
            </div>
  
            <!-- Category Name -->
            <div class="mb-4">
              <label for="name" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Category Name</label>
              <input
                type="text"
                id="name"
                v-model="category.name"
                class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
                required
              />
            </div>
            
          <!-- Image Upload -->
          <div class="mb-4">
  <label class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Category Image</label>
  <input type="file" @change="handleImageUpload" accept="image/*" />

  <!-- Image Preview -->
  <div v-if="categoryImageUrl || imagePreview" class="relative mt-2 w-32 h-32">
    <img :src="imagePreview || categoryImageUrl" alt="Category Image" class="w-full h-full object-cover rounded-md shadow-md">

    <!-- Remove Image Button -->
    <button @click.prevent="removeImage" class="absolute top-1 right-1 bg-red-600 text-white rounded-full p-1 flex items-center justify-center shadow-lg hover:bg-red-700 transition">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </button>
  </div>
</div>

  
            <!-- Parent Category Selection -->
            <div class="mb-4">
              <label for="parentCategory" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Parent Category</label>
              <select
                id="parentCategory"
                v-model="category.parent_id"
                class="w-full p-3 border rounded-lg dark:bg-gray-700 dark:text-white border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
              >
                <option value="">None</option>
                <option v-for="parent in parentCategories" :key="parent.id" :value="parent.id">
                  {{ parent.name }}
                </option>
              </select>
            </div>

            <div class="mb-4">
            <label class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Assign to Branches</label>
            <vue-multiselect
              v-model="category.branch_ids"
              :options="branches"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              label="name"
              track-by="id"
              placeholder="Select Branches"
            />
          </div>

          <div class="mb-4">
            <label class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Assign to Groups</label>
            <vue-multiselect
              v-model="category.group_ids"
              :options="groups"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              label="name"
              track-by="id"
              placeholder="Select Groups"
            />
          </div>
  
            <!-- Submit Button -->
            <div class="mt-6">
              <button
                type="submit"
                :disabled="isSubmitting"
                class="w-full !bg-secondary-600 text-white font-semibold py-3 rounded-lg hover:!bg-secondary-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 flex items-center justify-center"
              >
                <svg v-if="isSubmitting" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
                {{ isSubmitting ? 'Updating...' : 'Update Category' }}
              </button>
            </div>
          </form>
        </section>
      </main>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import TopNav from '@/components/headers/TopNav.vue';
  import SideMenu from '@/components/SideMenu.vue';
  import VueMultiselect from 'vue-multiselect';

  export default {
    components: {
      TopNav,
      SideMenu,
      VueMultiselect
    },
    data() {
      return {
        category: {
          id: null,
          name: '',
          parent_id: null,
          branch_ids: [],
          image: null
        },
        branches: [],
        groups: [],
        parentCategories: [],
        isSubmitting: false,
        errors: [],
        loadingCat: true,
        loadingParCat: true,
        imagePreview: null,
        isSidebarOpen: false,
      };
    },
    computed: {
    categoryImageUrl() {
      return this.category.image && !(this.category.image instanceof File) 
        ? `${process.env.VUE_APP_API_URL}${this.category.image}` 
        : null;
    }
  },
    methods: {
      toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
      async fetchCategory() {
        try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
            query: `
              query GetCategory($id: Int!) {
                category(id: $id) {
                  id
                  name
                  parent_id
                  image
                  branches { id name }
                  groups { id name }
                }
              }
            `,
            variables: {
              id: parseInt(this.$route.params.id),
            },
          });
  
          if (response.data.data.category) {
            this.category = response.data.data.category;
            this.category.branch_ids = this.category.branches;
            this.category.group_ids = this.category.groups || [];
          } else {
            this.errors.push('Category not found.');
          }
          this.loadingCat = false;
        } catch (error) {
          console.error('Error fetching category:', error);
          this.errors.push('An error occurred while fetching category data.');
          this.loadingCat = false;
        }
      },
      handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.category.image = file;
        this.imagePreview = URL.createObjectURL(file);
      }
    },
    removeImage() {
      this.category.image = null;
      this.imagePreview = null;
    },
      async fetchBranches() {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
        query: `query { branches { id name } }`
      });
      this.branches = response.data.data.branches || [];
    },
    async fetchGroups() {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
        query: `query { groups { id name } }`
      });
      this.groups = response.data.data.groups || [];
    },
      async fetchParentCategories() {
        try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
            query: `
              query {
                categories {
                  id
                  name
                }
              }
            `,
          });
  
          this.parentCategories = response.data.data.categories.filter(cat => cat.id !== this.category.id) || [];
          this.loadingParCat = false;
        } catch (error) {
          console.error('Error fetching categories:', error);
          this.errors.push('An error occurred while fetching parent categories.');
          this.loadingParCat = false;
        }
      },
  
      async updateCategory() {
  this.isSubmitting = true;
  this.errors = [];
  const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

  const formData = new FormData();

  // Ensure branch_ids is always an array before mapping
  const branchIds = Array.isArray(this.category.branch_ids) 
    ? this.category.branch_ids.map(branch => parseInt(branch.id)) 
    : [];
  const groupIds = Array.isArray(this.category.group_ids)
    ? this.category.group_ids.map(group => parseInt(group.id))
    : [];

  // Construct GraphQL variables
  const variables = {
    id: this.category.id,
    name: this.category.name,
    parent_id: this.category.parent_id || null,
    branch_ids: branchIds,
    group_ids: groupIds,
    branchUrl
  };

  let hasNewImage = false;

  // Handle Image Logic
  if (this.category.image === null) {
    variables.image = null; 
  } else if (this.category.image instanceof File) {
    variables.image = null;
    hasNewImage = true;
  }

  // Append the GraphQL query to FormData
  formData.append("operations", JSON.stringify({
    query: `
      mutation UpdateCategory($id: Int!, $name: String!, $parent_id: Int, $branch_ids: [Int], $group_ids: [Int], $image: Upload, $branchUrl: String) {
        updateCategory(id: $id, name: $name, parent_id: $parent_id, branch_ids: $branch_ids, group_ids: $group_ids, image: $image, branchUrl: $branchUrl) {
          id
          name
          image
        }
      }
    `,
    variables,
  }));

  // If an image is being uploaded, attach it properly
  if (hasNewImage) {
    formData.append("map", JSON.stringify({ "1": ["variables.image"] }));
    formData.append("1", this.category.image);
  } else {
    // ✅ Add empty map if no image is uploaded to prevent missing parameter error
    formData.append("map", JSON.stringify({}));
  }

  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.data.errors) {
      this.errors = response.data.errors.map(err => err.message);
    } else {
      this.$router.push('/categories');
    }
  } catch (error) {
    console.error("Error updating category:", error);
    this.errors.push("An error occurred while updating the category.");
  } finally {
    this.isSubmitting = false;
  }
}




    },
    async created() {
      await this.fetchCategory();
      await this.fetchParentCategories();
      this.fetchBranches();
      this.fetchGroups(); 
    },
  };
  </script>
  