<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
      <TopNav @toggle-sidebar="toggleSidebar"  />
      <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />
  
      <main class="p-6 md:ml-64 h-auto pt-20 flex justify-center">
        <section class="w-full max-w-lg dark:bg-gray-800 rounded-lg p-8">
            <div class="mb-6 flex justify-between items-center">
                <h2 class="text-3xl font-semibold text-gray-800 dark:text-gray-100">Create A New Group</h2>
                <a href="/groups" class="mt-4 ml-4 flex items-center text-primary-700 dark:text-primary-300 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                  </svg>
                  <span class="ml-2">Back</span>
                </a>
              </div>
  
          <form @submit.prevent="createGroup">
            <!-- Inline Error Messages -->
            <div v-if="errors.length" class="mb-4 bg-red-100 text-red-800 p-3 rounded">
              <ul>
                <li v-for="(error, index) in errors" :key="index" class="text-sm">{{ error }}</li>
              </ul>
            </div>
  
            <!-- Group Name -->
            <div class="mb-4">
              <label for="name" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Group Name</label>
              <input
                type="text"
                id="name"
                v-model="group.name"
                class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
                required
              />
            </div>
  
            <!-- Group Description -->
            <div class="mb-4">
              <label for="description" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Description</label>
              <textarea
                id="description"
                v-model="group.description"
                class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
              ></textarea>
            </div>
  
            <!-- Submit Button -->
            <div class="mt-6">
                <button type="submit" :disabled="isSubmitting" class="w-full !bg-secondary-600 text-white font-semibold py-3 rounded-lg hover:!bg-secondary-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 flex items-center justify-center">
                    <svg v-if="isSubmitting" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
                {{ isSubmitting ? 'Creating...' : 'Create Group' }}
              </button>
            </div>
          </form>
        </section>
      </main>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import TopNav from '@/components/headers/TopNav.vue';
  import SideMenu from '@/components/SideMenu.vue';
  
  export default {
    components: {
      TopNav,
      SideMenu,
    },
    data() {
      return {
        group: {
          name: '',
          description: '',
        },
        isSubmitting: false,
        errors: [],
        isSidebarOpen: false,
      };
    },
    methods: {
      toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
      async createGroup() {
        this.isSubmitting = true;
        this.errors = [];
        const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

        try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
            query: `
              mutation CreateGroup($input: GroupInput!, $branchUrl: String) {
                createGroup(input: $input, branchUrl: $branchUrl) {
                  id
                  name
                  description
                }
              }
            `,
            variables: {
              input: {
                name: this.group.name,
                description: this.group.description,
              },
              branchUrl,
            },
          });
  
          // Reset the form on success
          if (response.data.data.createGroup) {
            this.group = { name: '', description: '' };
            this.$router.push({ name: 'UserGroups' }); // Redirect to groups overview
          }
        } catch (error) {
          console.error('Error creating group:', error);
          this.errors = ['An error occurred while creating the group.'];
        } finally {
          this.isSubmitting = false;
        }
      },
    },
  };
  </script>
  