<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
        <TopNav @toggle-sidebar="toggleSidebar" />
        <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />

        <main class="p-6 md:ml-64 h-auto pt-20 flex justify-center">
            <section class="w-full max-w-4xl dark:bg-gray-800 rounded-lg p-8">
                <div class="mb-6 flex justify-between items-center">
                    <h2 class="text-3xl font-semibold text-gray-800 dark:text-gray-100">Bulk Upload Users</h2>
                    <a href="/users" class="flex items-center text-primary-700 dark:text-primary-300">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                        <span class="ml-2">Back</span>
                    </a>
                </div>

                <!-- File Upload -->
                <input type="file" @change="handleFileUpload" accept=".csv"
                    class="mb-4 p-3 w-full border rounded-lg dark:bg-gray-700 dark:text-gray-100 border-gray-300 focus:ring-2 focus:ring-primary-500" />

                <!-- Error Message -->
                <div v-if="errorMessage" class="mb-4 p-3 text-red-600 bg-red-200 rounded-lg">
                    <strong>Error:</strong> {{ errorMessage }}
                </div>

                <!-- Preview Table -->
                <div v-if="users.length">
                    <h3 class="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-100">User Preview</h3>
                    <div class="overflow-auto">
                        <table class="w-full border-collapse bg-white dark:bg-gray-800 rounded-lg shadow-md">
                            <thead class="bg-gray-200 dark:bg-gray-700">
                                <tr>
                                    <th class="p-3">First Name</th>
                                    <th class="p-3">Last Name</th>
                                    <th class="p-3">Email</th>
                                    <th class="p-3">Role</th>
                                    <th class="p-3">Branch</th>
                                    <th class="p-3">Group</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(user, index) in users" :key="index">
                                    <td class="p-3">{{ user.first_name }}</td>
                                    <td class="p-3">{{ user.last_name }}</td>
                                    <td class="p-3">{{ user.email }}</td>
                                    <td class="p-3">{{ user.role }}</td>
                                    <td class="p-3">{{ Array.isArray(user.branchUrls) ? user.branchUrls.join(', ') : '-' }}</td>
                                    <td class="p-3">{{ Array.isArray(user.groupNames) ? user.groupNames.join(', ') : '-' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Submit Button -->
                    <button @click="uploadUsers" :disabled="isUploading"
                        class="mt-4 w-full bg-secondary-600 text-white font-semibold py-3 rounded-lg hover:bg-secondary-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 flex items-center justify-center">
                        <svg v-if="isUploading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                        </svg>
                        {{ isUploading ? 'Uploading...' : 'Upload Users' }}
                    </button>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import TopNav from "@/components/headers/TopNav.vue";
import SideMenu from "@/components/SideMenu.vue";
import Papa from "papaparse";
import axios from "axios";
import moment from "moment";

export default {
    components: {
        TopNav,
        SideMenu,
    },
    data() {
        return {
            users: [],
            isUploading: false,
            isSidebarOpen: false,
            errorMessage: null, // Store errors here
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (!file) return;

            this.errorMessage = null; // Reset errors when uploading a new file

            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    try {
                        this.users = results.data.map(user => ({
                            first_name: user.first_name.trim(),
                            last_name: user.last_name.trim(),
                            email: user.email.trim(),
                            password: user.password.trim(),
                            role: user.role.trim(),
                            is_primary: String(user.is_primary).toLowerCase() === "true",
                            is_active: String(user.is_active).toLowerCase() === "true",
                            skip_email: String(user.skip_email).toLowerCase() === "true",
                            deactivate_at: user.deactivate_at 
                                ? moment(user.deactivate_at, "DD/MM/YYYY").format("YYYY-MM-DD") 
                                : null,
                            branchUrls: user.branch_url 
                                ? user.branch_url.split(',').map(b => b.trim()) 
                                : [], 
                            groupNames: user.group_name 
                                ? user.group_name.split(',').map(g => g.trim()) 
                                : [], 
                        }));
                    } catch (error) {
                        console.error("CSV Parsing Error:", error);
                        this.errorMessage = "Error processing the CSV file. Please check formatting.";
                    }
                },
            });
        },
        async uploadUsers() {
            if (!this.users.length) return;
            this.isUploading = true;
            this.errorMessage = null;

            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
                    query: `
                      mutation BulkCreateUsers($input: [UserInput!]!) {
                        BulkCreateUsers(input: $input) {
                          id
                          first_name
                          last_name
                          email
                          role
                          branchUrl
                          groups {
                            name
                          }
                        }
                      }
                    `,
                    variables: {
                        input: this.users,
                    },
                });

                if (response.data.errors) {
    console.error("Error uploading users:", response.data.errors);

    const errorMessages = response.data.errors.flatMap(err => {
        if (err.extensions && err.extensions.validation) {
            return Object.entries(err.extensions.validation).map(([field, messages]) => {
                // Extract the user index from the field name (e.g., "input.0.email")
                const match = field.match(/input\.(\d+)\./);
                if (match) {
                    const userIndex = parseInt(match[1]) + 1; // Convert to 1-based index
                    return `User ${userIndex}: ${messages.join(", ")}`;
                }
                return messages.join(", ");
            });
        }
        return err.message;
    });

    this.errorMessage = [...new Set(errorMessages)].join("\n") || "An unknown validation error occurred.";
}


 else {
                    console.log("Users created successfully:", response.data.data.BulkCreateUsers);
                    this.$router.push({ name: "AdminUsers" });
                }
            } catch (error) {
                console.error("An error occurred:", error);
                this.errorMessage = "An unexpected error occurred while uploading users.";
            } finally {
                this.isUploading = false;
            }
        },
    },
};
</script>
