<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900">
    <TopNav @toggle-sidebar="toggleSidebar"  />

    <!-- Sidebar -->
    <AddCourseSideMenu @publish-course="updateCourse" :courseTitle="courseData.newCourse.title"
      @option-selected="handleOptionSelected" @unit-selected="handleUnitSelected" @view-new-course="viewNewCourse"
      :cards="courseData.units" @unit-order-updated="handleUnitOrderUpdated" :mode="'edit'"
      :thumbnailUrl="courseData.newCourse.thumbnail_url" />

    <main class="p-4 md:ml-96 h-auto pt-20">
      <!-- Spinner with message -->
      <div v-if="isLoading" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="absolute inset-0 bg-gray-700 opacity-60"></div>
        <div class="relative z-10 text-center">
          <svg class="animate-spin h-16 w-16 text-secondary-600 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
          </svg>
          <p class="text-md text-white mt-2 font-semibold drop-shadow-xl">
            Uploading content and files, please wait... ({{ currentUploadIndex }}/{{ totalUploads }})
          </p>
        </div>
      </div>

      <!-- Display Validation Errors -->
      <div v-if="validationErrors.length"
        class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6">

        <strong class="font-bold">Please fix the following errors:</strong>
        <ul>
          <li v-for="error in validationErrors" :key="error">{{ error }}</li>
        </ul>
      </div>

      <!-- Conditionally render EditCourse or selected unit -->
      <component :is="getComponentName(selectedUnit?.type)" v-if="selectedUnit" :courseData="courseData.newCourse"
        :unitOrder="selectedUnit.unit_order" :unitData="selectedUnit.data" @data-updated="updateUnitData"
        class="w-full max-w-7xl mx-auto" />

    </main>
  </div>
</template>

<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import AddCourseSideMenu from '@/components/AddCourseSideMenu.vue';
import NewCourse from '@/components/NewCourse.vue';
import ContentUnit from '@/components/AddCourse/ContentUnit.vue';
import VideoUnit from '@/components/AddCourse/VideoUnit.vue';
import AudioUnit from '@/components/AddCourse/AudioUnit.vue';
import DocumentUnit from '@/components/AddCourse/DocumentUnit.vue';
import iFrameUnit from '@/components/AddCourse/iFrameUnit.vue';
import ScormUnit from '@/components/AddCourse/ScormUnit.vue';
import TestUnit from '@/components/AddCourse/TestUnit.vue';
import AssignmentUnit from '@/components/AddCourse/AssignmentUnit.vue';

export default {
  data() {
    return {
      selectedUnit: null, // Track the currently selected unit
      unit_counter: 0, // Initialize the unit counter
      validationErrors: [], // New property to track validation errors
      isLoading: false,
      courseData: {
        newCourse: {
          title: '',
          description: '',
          course_code: '',
          category: '',
          categories: [],
          price: null,
          hide_from_catalog: false,
          capacity: null,
          level: '',
          start_date: '',
          start_time: '',
          end_date: '',
          end_time: '',
          completion_rules: '',
          completion_percentage: null,
          learning_path: [],
          certificate_type: '',
          resources: []
        },
        units: []
      },
    };
  },
  async created() {
    const courseId = this.$route.params.id;
    await this.loadCourseData(courseId);

    // Removed duplicate NewCourse unit insertion
  },


  components: {
    TopNav,
    AddCourseSideMenu,
    NewCourse,
    ContentUnit,
    VideoUnit,
    AudioUnit,
    DocumentUnit,
    iFrameUnit,
    ScormUnit,
    TestUnit,
    AssignmentUnit
  },
  methods: {
    async loadCourseData(courseId) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
        query GetCourse($id: Int!) {
          course(id: $id) {
            id
            title
            description
            learning_path
            course_code
            price
            category {
              id
              name
            }
            categories {
              id
              name
              parent {
                id
                name
              }
            }
            resources {
              id
              name
              file_path
              type
            }
            hide_from_catalog
            capacity
            level
            start_date
            end_date
            start_time
            end_time
            completion_rules
            completion_percentage
            certificate_type
            thumbnail
            prerequisites {
              id
              title
              is_complete
            }
            units {
              contents {
                id
                title
                unit_order
                content
                completion_option
                time_limit
                is_complete
                tests {
                  id
                  question
                  type
                  pairs {
                    left
                    right
                  }
                  options {
                    text
                    checked
                  }
                  rules {
                    condition
                    word
                    points
                  }
                  required_points
                }
                __typename
              }
              videos {
                id
                title
                unit_order
                video_path
                youtube_link
                completion_option
                time_limit
                content
                is_complete
                tests {
                  id
                  question
                  type
                  pairs {
                    left
                    right
                  }
                  options {
                    text
                    checked
                  }
                  rules {
                    condition
                    word
                    points
                  }
                  required_points
                }
                __typename
              }
              audios {
                id
                title
                unit_order
                audio_path
                completion_option
                time_limit
                is_complete
                content
                tests {
                  id
                  question
                  type
                  pairs {
                    left
                    right
                  }
                  options {
                    text
                    checked
                  }
                  rules {
                    condition
                    word
                    points
                  }
                  required_points
                }
                __typename
              }
              documents {
                id
                title
                unit_order
                file_path
                completion_option
                time_limit
                is_complete
                description
                __typename
                tests {
                  id
                  question
                  type
                  pairs {
                    left
                    right
                  }
                  options {
                    text
                    checked
                  }
                  rules {
                    condition
                    word
                    points
                  }
                  required_points
                }
                __typename
              }
              iframes {
                id
                title
                unit_order
                url
                completion_option
                time_limit
                is_complete
                content
                tests {
                  id
                  question
                  type
                  pairs {
                    left
                    right
                  }
                  options {
                    text
                    checked
                  }
                  rules {
                    condition
                    word
                    points
                  }
                  required_points
                }
                __typename
              }
              scorms {
                id
                title
                unit_order
                scorm_path
                scorm_id
                is_complete
                scorm {
                  id
                  title
                  version
                  entry_url
                  uuid
                  scos {
                    id
                    title
                    uuid
                    entry_url
                  }
                }
                __typename
              }
              tests {
                id
                unit_order
                is_complete
                tests {
                  id
                  question
                  type
                  pairs {
                    left
                    right
                  }
                  options {
                    text
                    checked
                  }
                  rules {
                    condition
                    word
                    points
                  }
                  required_points
                }
                __typename
              }
              assignments {
                id
                title
                unit_order
                content
                completion_option
                is_complete
                __typename
              }
            }
          }
        }
      `,
          variables: { id: parseInt(courseId, 10) },
        });

        // Check if the course exists in the response
        const course = response.data?.data?.course;
        if (!course) {
          throw new Error("Course data not found in the response");
        }

        // Populate course data
        this.populateCourseData(course);
      } catch (error) {
        console.error("Failed to load course data:", error.message || error);
      }
    },
    populateCourseData(course) {
      const optionIcons = {
        Content: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M19 7h1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h11.5M7 14h6m-6 3h6m0-10h.5m-.5 3h.5M7 7h3v3H7V7Z',
          iconFill: 'none',
        },
        Video: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M14 6H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Zm7 11-6-2V9l6-2v10Z',
          iconFill: 'none',
        },
        Audio: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M10 3v4a1 1 0 0 1-1 1H5m8 7.5V8s3 1 3 4m3-8v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Zm-6 12c0 1.105-1.12 2-2.5 2S8 17.105 8 16s1.12-2 2.5-2 2.5.895 2.5 2Z',
          iconFill: 'none',
        },
        Document: {
          iconClass: 'h-15 w-15 text-gray-600 dark:text-gray-400',
          iconPath: 'M12 6.03v13m0-13c-2.819-.831-4.715-1.076-8.029-1.023A.99.99 0 0 0 3 6v11c0 .563.466 1.014 1.03 1.007 3.122-.043 5.018.212 7.97 1.023m0-13c-2.819-.831-4.715-1.076-8.029-1.023A.99.99 0 0 1 21 6v11c0 .563-.466 1.014-1.03 1.007-3.122-.043-5.018.212-7.97 1.023',
          iconFill: 'none',
        },
        Iframe: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'm8 8-4 4 4 4m8 0 4-4-4-4m-2-3-4 14',
          iconFill: 'none',
        },
        Test: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z',
          iconFill: 'none',
        },
        Assignment: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M18 5V4a1 1 0 0 0-1-1H8.914a1 1 0 0 0-.707.293L4.293 7.207A1 1 0 0 0 4 7.914V20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5M9 3v4a1 1 0 0 1-1 1H4m11.383.772 2.745 2.746m1.215-3.906a2.089 2.089 0 0 1 0 2.953l-6.65 6.646L9 17.95l.739-3.692 6.646-6.646a2.087 2.087 0 0 1 2.958 0Z',
          iconFill: 'none',
        },
        Scorm: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M13.5 8H4m0-2v13a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-5.032a1 1 0 0 1-.768-.36l-1.9-2.28a1 1 0 0 0-.768-.36H5a1 1 0 0 0-1 1Z',
          iconFill: 'none',
        },
      };

      const formattedCategory = course.category?.name || course.category || '';

      const formattedResources = (course.resources || []).map(resource => ({
        id: resource.id || null,
        file: null, // Existing resources don't have file objects
        name: resource.name || 'Unnamed Resource',
        previewUrl: resource.type?.startsWith('image') && resource.file_path
          ? `${process.env.VUE_APP_API_URL}/${resource.file_path.replace(/^\/+/, '')}`
          : null,
        uploadInProgress: false,
        file_path: resource.file_path || null,
        type: resource.type, // Use 'unknown' for type if not provided
        uploaded: true
      }));

      // Initialize new course data
      this.courseData.newCourse = {
        title: course.title,
        description: course.description,
        course_code: course.course_code,
        category: formattedCategory, // Format category as a string
        categories: course.categories || [],
        price: course.price,
        hide_from_catalog: course.hide_from_catalog,
        capacity: course.capacity,
        level: course.level,
        start_date: course.start_date,
        start_time: course.start_time,
        end_date: course.end_date,
        end_time: course.end_time,
        completion_rules: course.completion_rules,
        completion_percentage: course.completion_percentage,
        learning_path: course.learning_path,
        certificate_type: course.certificate_type,
        thumbnail_url: course.thumbnail
          ? course.thumbnail.startsWith('http')
            ? course.thumbnail // Use as is if already a full URL
            : `${process.env.VUE_APP_API_URL}/${course.thumbnail.replace(/^\/+/, '')}` // Append base URL only if relative
          : '',
        resources: formattedResources
      };


      const flattenedUnits = [];

      Object.keys(course.units).forEach((unitType) => {
        const typeName = unitType.charAt(0).toUpperCase() + unitType.slice(1, -1);

        course.units[unitType].forEach((unit) => {
          const icon = optionIcons[typeName] || {};

          // Handle media fields
          const mediaFields = ['audio_path', 'file_path', 'video_path', 'scorm_path'];
          mediaFields.forEach((field) => {
            if (
              unit[field] &&
              !unit[field].startsWith('http') && // Avoid duplicating base URL
              !unit[field].startsWith('blob:')
            ) {
              unit[field] = `${process.env.VUE_APP_API_URL}/${unit[field].replace(/^\/+/, '')}`;
            }
          });

          // Map unit fields consistently
          const formattedUnit = {
            type: typeName,
            unit_order: unit.unit_order || 0,
            title: unit.title || `${typeName} Unit`,
            iconClass: icon.iconClass || '',
            iconPath: icon.iconPath || '',
            iconFill: icon.iconFill || '',
            data: {
              id: unit.id,
              title: unit.title,
              content: typeName === 'Document' ? unit.description || '' : unit.content || '',
              completion_option: unit.completion_option || null,
              time_limit: unit.time_limit || null,
              is_complete: unit.is_complete || false,
              tests: unit.tests || [], // Include tests explicitly
              timeLimit: unit.time_limit || null,
              questions: unit.tests || [], // Alias tests as questions
              ...(typeName === 'Scorm' && {
                scorm_path: unit.scorm_path || null,
                scorm_id: unit.scorm_id || null, // Include scorm_id for Scorm units
              }),
              ...(typeName === 'Iframe' && {
                url: unit.url || null, // Include url for Iframe units
              }),
              ...(typeName === 'Document' && {
                url: unit.url || null, // If there's a direct URL field, include it
                doc_path: unit.file_path?.startsWith('blob:') || unit.file_path?.startsWith('http')
                  ? unit.file_path
                  : unit.file_path
                    ? `${process.env.VUE_APP_API_URL}/${unit.file_path.replace(/^\/+/, '')}`
                    : null, // Correctly handle blob and absolute URLs
                file: unit.file || null, // Include file if available (optional, based on your form data)
              }),
              ...(typeName === 'Video' && {
                video_path: unit.video_path
                  ? unit.video_path.startsWith('http')
                    ? unit.video_path
                    : `${process.env.VUE_APP_API_URL}/${unit.video_path.replace(/^\/+/, '')}`
                  : null, // Ensure video_path is properly formatted
                youtube_link: unit.youtube_link || null, // Include youtube_link as is
              }),
              ...(typeName === 'Audio' && {
                audio_path: unit.audio_path
                  ? unit.audio_path.startsWith('http')
                    ? unit.audio_path
                    : `${process.env.VUE_APP_API_URL}/${unit.audio_path.replace(/^\/+/, '')}`
                  : null, // Properly handle audio_path
              }),

            },
          };
          flattenedUnits.push(formattedUnit);
        });
      });

      // Add default "NewCourse" unit
      flattenedUnits.unshift({
        type: 'NewCourse',
        unit_order: 0,
        title: this.courseData.newCourse.title || 'New Course',
        iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
        iconPath: 'M3 3h18v18H3V3Z',
        iconFill: 'none',
        data: this.courseData.newCourse,
      });
      const newCourseUnit = flattenedUnits.find(u => u.type === 'NewCourse');
      const otherUnits = flattenedUnits.filter(u => u.type !== 'NewCourse');
      otherUnits.sort((a, b) => a.unit_order - b.unit_order);

      // Set course units and select the first one by default
      this.courseData.units = [newCourseUnit, ...otherUnits];
      this.selectedUnit = this.courseData.units[0];
    },

    async updateCourse() {
      if (!this.validateFields()) {
        return; // Exit if validation fails
      }

      this.isLoading = true; // Display the spinner

// Collect all upload promises from units and resources
const uploadPromises = [];

this.courseData.units.forEach((unit) => {
  if (unit.data?.uploadPromise) {
    uploadPromises.push(unit.data.uploadPromise);
  }
  if (unit.data?.resources) {
    unit.data.resources.forEach((resource) => {
      if (resource.uploadPromise) {
        uploadPromises.push(resource.uploadPromise);
      }
    });
  }
});

this.totalUploads = uploadPromises.length; // Count the uploads
this.currentUploadIndex = 0; // Reset progress

try {
  if (uploadPromises.length > 0) {
    console.log("Waiting for all uploads to complete...");
    // Increment progress as each promise resolves
    await Promise.all(
      uploadPromises.map((promise) =>
        promise.finally(() => {
          this.currentUploadIndex++;
        })
      )
    );
  }
        // Proceed with thumbnail upload if required
        const thumbnailUpload = this.courseData.newCourse.thumbnail_file
          ? (async () => {
            const formData = new FormData();
            formData.append("image", this.courseData.newCourse.thumbnail_file);

            const response = await axios.post(
              `${process.env.VUE_APP_API_URL}/api/upload-image`,
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );

            // Ensure URL is updated correctly
            const uploadedUrl = response.data.url;
            this.courseData.newCourse.thumbnail_url = uploadedUrl.startsWith('http')
              ? uploadedUrl
              : `${process.env.VUE_APP_API_URL}/${uploadedUrl.replace(/^\/+/, '')}`;

            delete this.courseData.newCourse.thumbnail_file;
          })()
          : Promise.resolve();


        await thumbnailUpload; // Wait for thumbnail upload to complete
        const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

        // GraphQL mutation
        const mutation = `
  mutation EditCourse($input: EditCourseInput!, $branchUrl: String) {
    EditCourse(input: $input, branchUrl: $branchUrl) {
            id
            title
            description
            learning_path
            course_code
            price
            hide_from_catalog
            capacity
            level
            start_date
            end_date
            start_time
            end_time
            completion_rules
            completion_percentage
            certificate_type
            thumbnail
            category {
            name
            }
            categories {
            name
            parent {
            name
            }
            }            
            resources {
            id
            name
            file_path
            type
          }
      contents {
        id
        title
        content
        unit_order
        time_limit
        completion_option
      }
      videos {
        id
        title
        video_path
        unit_order
        completion_option
        time_limit
        content
      }
      audios {
        id
        title
        audio_path
        unit_order
        completion_option
        time_limit
        content
      }
      documents {
        id
        title
        file_path
        unit_order
        completion_option
        time_limit
        description
      }
      iframes {
        id
        title
        unit_order
        completion_option
        url
        time_limit
        content
      }
      scorms {
        id
        title
        scorm_path
        scorm_id
        unit_order
      }
      assignments {
        id
        title
        content
        completion_option
        unit_order
      }
      unit_tests {
        id
        question
        type
        pairs {
          left
          right
        }
        options {
          text
          checked
        }
        rules {
          condition
          word
          points
        }
        required_points
      }
      tests {
        id
        unit_order
      }
    }
  }
`;

        // Variables for the mutation
        const variables = {
          input: {
            id: parseInt(this.$route.params.id, 10),
            title: this.courseData.newCourse.title,
            description: this.courseData.newCourse.description,
            course_code: this.courseData.newCourse.course_code,
            price: this.courseData.newCourse.price,
            hide_from_catalog: this.courseData.newCourse.hide_from_catalog,
            capacity: this.courseData.newCourse.capacity,
            level: this.courseData.newCourse.level != null ? this.courseData.newCourse.level.toString() : '',
            start_date: this.courseData.newCourse.start_date,
            start_time: this.courseData.newCourse.start_time,
            end_date: this.courseData.newCourse.end_date,
            end_time: this.courseData.newCourse.end_time,
            completion_rules: this.courseData.newCourse.completion_rules,
            completion_percentage: this.courseData.newCourse.completion_percentage,
            learning_path: this.courseData.newCourse.learning_path,
            thumbnail: this.courseData.newCourse.thumbnail_url,
            category: this.courseData.newCourse.category || '',
            categories: this.courseData.newCourse.categories.map(category => ({
              id: category.id,
              name: category.name,
              parent_id: category.parent?.id ?? null // Ensure parent_id is sent instead of parent object
            })),
            certificate_type: this.courseData.newCourse.certificate_type,
            resources: this.courseData.newCourse.resources.map((resource) => ({
              id: parseInt(resource.id) || null,
              name: resource.name,
              file_path: resource.file_path,
              type: resource.type,
            })),
            contents: this.courseData.units
              .filter(unit => unit.type === 'Content')
              .map(unit => ({
                id: parseInt(unit.data?.id) || null,
                title: unit.data.title,
                description: unit.data.content,
                unit_order: unit.unit_order ?? 0, // Default to 0 if not provided
                completion_option: unit.data.completion_option,
                time_limit: unit.data.time_limit,
              })),
            videos: this.courseData.units
              .filter(unit => unit.type === 'Video')
              .map(unit => ({
                id: parseInt(unit.data?.id) || null,
                title: unit.data.title,
                video_path: unit.data.video_path,
                youtube_link: unit.data.youtube_link || null,
                unit_order: unit.unit_order ?? 0,
                completion_option: unit.data.completion_option,
                time_limit: unit.data.time_limit,
                content: unit.data.content
              })),
            audios: this.courseData.units
              .filter(unit => unit.type === 'Audio')
              .map(unit => ({
                id: parseInt(unit.data?.id) || null,
                title: unit.data.title,
                audio_path: unit.data.audio_path,
                unit_order: unit.unit_order ?? 0,
                completion_option: unit.data.completion_option,
                time_limit: unit.data.time_limit,
                content: unit.data.content
              })),
            documents: this.courseData.units
              .filter(unit => unit.type === 'Document')
              .map(unit => ({
                id: parseInt(unit.data?.id) || null,
                title: unit.data.title,
                content: unit.data.content,
                file_path: unit.data.doc_path,
                unit_order: unit.unit_order ?? 0,
                completion_option: unit.data.completion_option,
                time_limit: unit.data.time_limit,
              })),
            iframes: this.courseData.units
              .filter(unit => unit.type === 'Iframe')
              .map(unit => ({
                id: parseInt(unit.data?.id) || null,
                title: unit.data.title,
                url: unit.data.url,
                unit_order: unit.unit_order ?? 0,
                completion_option: unit.data.completion_option,
                time_limit: unit.data.time_limit,
                content: unit.data.content
              })),
            scorms: this.courseData.units
              .filter(unit => unit.type === 'Scorm')
              .map(unit => ({
                id: parseInt(unit.data?.id) || null,
                title: unit.data.title,
                scorm_path: unit.data.scorm_path,
                scorm_id: parseInt(unit.data.scorm_id),
                unit_order: unit.unit_order ?? 0,
              })),
            assignments: this.courseData.units
              .filter(unit => unit.type === 'Assignment')
              .map(unit => ({
                id: parseInt(unit.data?.id) || null,
                title: unit.data.title,
                content: unit.data.content,
                unit_order: unit.unit_order ?? 0,
                completion_option: unit.data.completion_option,
              })),
            unit_tests: [
              // Extract unit_tests from all units
              ...this.courseData.units
                .flatMap(unit =>
                  unit.data?.questions?.map(test => ({
                    id: test.id || null,
                    question: test.question,
                    type: test.type,
                    pairs: test.pairs || [],
                    options: test.options || [],
                    rules: test.rules || [],
                    required_points: test.required_points || null,
                    unit_order: unit.unit_order ?? 0,
                  })) || []
                ),
              // Add additional logic for Test unit tests
              ...this.courseData.units
                .filter(unit => unit.type === 'Test')
                .flatMap(unit =>
                  unit.data?.questions?.map(question => ({
                    id: question.id || null,
                    question: question.question,
                    type: question.type,
                    pairs: question.pairs || [],
                    options: question.options || [],
                    rules: question.rules || [],
                    required_points: question.requiredPoints || null,
                    unit_order: unit.unit_order ?? 0,
                  })) || []
                ),
            ],
            tests: this.courseData.units
              .filter(unit => unit.type === 'Test')
              .map(unit => ({
                id: unit.data?.id || null,
                unit_order: unit.unit_order ?? 0,
              })),
          },
          branchUrl,
        };



        // API call
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: mutation,
          variables,
        });

        const updatedCourse = response.data.data.EditCourse;
        console.log('Course updated successfully:', updatedCourse);

        // Redirect or success message
        this.$router.push({ name: "Courses" });

      } catch (error) {
        console.error('Failed to update course:', error.message || error);
      } finally {
        this.isLoading = false;
      }
    },
    handleUnitOrderUpdated(updatedCards) {
      // Separate NewCourse from other units
      const newCourseUnit = this.courseData.units.find((unit) => unit.type === 'NewCourse');
      const otherUnits = updatedCards.filter((unit) => unit.type !== 'NewCourse');

      // Reassign unit_order to the reordered units
      const reorderedUnits = otherUnits.map((unit, index) => ({
        ...unit,
        unit_order: index + 1, // Adjust unit_order starting from 1
      }));

      // Combine NewCourse with the reordered units
      this.courseData.units = [newCourseUnit, ...reorderedUnits];
    },

    viewNewCourse() {
      const newCourseUnit = this.courseData.units.find((unit) => unit.type === 'NewCourse');
      this.selectedUnit = newCourseUnit;
    },

    handleUnitSelected(unit) {
      const selected = this.courseData.units.find(
        (u) => u.unit_order === unit.unit_order || (u.type === 'NewCourse' && unit.type === 'NewCourse')
      );
      if (selected) {
        this.selectedUnit = selected;
      } else {
        console.error('Unit not found for selection:', unit);
      }
    },

    handleOptionSelected(selectedOption) {
      if (selectedOption.type === 'iFrame') {
        selectedOption.type = 'Iframe';
      }
      if (selectedOption.type === 'Assignments') {
        selectedOption.type = 'Assignment';
      }

      // Find the current highest unit_order in existing units
      const lastUnitOrder = this.courseData.units.reduce((max, unit) => Math.max(max, unit.unit_order || 0), 0);

      // Assign the next unit_order value by incrementing the last unit_order
      const newUnit = {
        type: selectedOption.type,
        unit_order: lastUnitOrder + 1, // Increment the unit order
        title: `${selectedOption.type} Unit ${lastUnitOrder + 1}`, // Set a default title for the unit
        iconClass: selectedOption.iconClass || '',
        iconPath: selectedOption.iconPath || '',
        iconFill: selectedOption.iconFill || '',
        data: {}
      };

      // Push the new unit into the courseData.units array
      this.courseData.units.push(newUnit);

      // Set the newly added unit as the selected unit
      this.selectedUnit = newUnit;
    },


    getComponentName(type) {
      switch (type) {
        case 'Content':
          return 'ContentUnit';
        case 'Video':
          return 'VideoUnit';
        case 'Audio':
          return 'AudioUnit';
        case 'Presentation': // Handle "Presentation" as an alias for DocumentUnit
        case 'Document':     // Fix for the data type returned as DocumentUnit
          return 'DocumentUnit';
        case 'Iframe': // Fix case sensitivity for iFrame
          return 'iFrameUnit';
        case 'Test':
          return 'TestUnit';
        case 'Assignment': // Fix singular form for Assignments
          return 'AssignmentUnit';
        case 'Scorm':
          return 'ScormUnit';
        case 'NewCourse':
          return 'NewCourse';
        default:
          console.warn(`Unknown component type: ${type}`);
          return null;
      }
    },
    validateFields() {
      this.validationErrors = [];
      if (!this.courseData.newCourse.title) {
        this.validationErrors.push('Course Title is required');
      }
      if (!this.courseData.newCourse.description) {
        this.validationErrors.push('Course Description is required');
      }
      return this.validationErrors.length === 0;
    },
    updateNewCourseData(data) {
      this.courseData.newCourse = { ...this.courseData.newCourse, ...data };
      if (this.selectedUnit && this.selectedUnit.type === 'NewCourse') {
        this.selectedUnit.data = this.courseData.newCourse;
      }
    },
    async uploadFile(file, unitType, unitOrder) {
      // Find the unit by unitOrder
      const unitIndex = this.courseData.units.findIndex(
        (unit) => unit.unit_order === unitOrder
      );

      if (unitIndex === -1) {
        console.error("Unit not found for upload:", unitOrder);
        return Promise.reject("Unit not found");
      }

      const unit = this.courseData.units[unitIndex];

      if (!unit.data) {
        unit.data = { resources: [] }; // Default structure
      }

      if (!unit.data.resources) {
        unit.data.resources = [];
      }

      const resource = unit.data.resources.find(
        (res) => res.name === file.name
      );

      let fileLocation = `upload-${unitType.toLowerCase()}`; // Default to standard endpoint

      if (resource) {
        fileLocation = `upload-resource`; // Switch to resource-specific endpoint
        resource.uploadInProgress = true; // Mark resource as uploading
      } else {
        unit.data.uploadInProgress = true; // Mark media file as uploading
      }


      // Check if upload is already completed
      if (unit.data.uploaded) {
        return Promise.resolve();
      }

      try {
        const fileType = unitType.toLowerCase();
        const formData = new FormData();
        formData.append(resource ? 'resource' : unitType.toLowerCase(), file);

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/${fileLocation}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );


        if (resource) {
          resource.file_path = response.data.url;
          resource.uploaded = true; // Mark resource as uploaded

        } else {
          // Update the file path and mark as uploaded
          unit.data[`${fileType}_path`] = response.data.url;
          unit.data.uploaded = true;
        }

        if (fileType === 'scorm') {
          unit.data.scorm_id = response.data.scorm.id;
        }

        return response.data; // Return response for further use
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error; // Re-throw to handle it elsewhere
      } finally {
        if (resource) {
          resource.uploadInProgress = false; // Reset upload state for resource
        } else {
          unit.data.uploadInProgress = false; // Reset upload state for standalone media
        }
      }
    },
    async updateUnitData(data) {
  if (!this.selectedUnit) {
    console.error("No selected unit found to update.");
    return;
  }

  const unitOrder = this.selectedUnit.unit_order;
  const unitIndex = this.courseData.units.findIndex(
    (unit) => unit.unit_order === unitOrder
  );

  if (unitIndex === -1) {
    console.error("Unit not found for update:", unitOrder);
    return;
  }

  const unit = this.courseData.units[unitIndex];

  if (!unit.data) {
    unit.data = { resources: [] };
  }

  if (!unit.data.resources) {
    unit.data.resources = [];
  }

  const file = data.file;
  let resources = data.resources || [];

  // Map resources to ensure consistency
  resources = resources.map((resource) => ({
    ...resource,
    uploadInProgress: resource.uploadInProgress || false,
    uploaded: resource.uploaded || false,
    file_path: resource.file_path || null,
  }));

  // Sync resources
  unit.data.resources = resources;

  // Handle file uploads
  if (file && file instanceof File && !unit.data.uploadInProgress) {
    const uploadPromise = this.uploadFile(file, this.selectedUnit.type, unitOrder);
    unit.data.uploadPromise = uploadPromise;
  }

  resources.forEach((resource) => {
    if (resource.file && !resource.uploadInProgress && !resource.uploaded) {
      const uploadPromise = this.uploadFile(resource.file, this.selectedUnit.type, unitOrder);

      resource.uploadInProgress = true;
      resource.uploadPromise = uploadPromise;

      uploadPromise
        .then((response) => {
          resource.file_path = response.url;
          resource.uploaded = true;
        })
        .finally(() => {
          resource.uploadInProgress = false;
        });
    }
  });

  // Update unit's data and sync newCourse
  this.courseData.units[unitIndex].data = {
    ...unit.data,
    ...data,
    file: file || null,
    resources,
  };

  this.courseData.newCourse.resources = [
    ...new Set([...this.courseData.newCourse.resources, ...resources]),
  ];

  // Update title if provided
  this.courseData.units[unitIndex].title = data.title?.trim() || `${unit.type} Unit ${unit.unit_order}`;
  this.selectedUnit.data = this.courseData.units[unitIndex].data;

  if (this.selectedUnit.type === "NewCourse") {
    this.updateNewCourseData(this.selectedUnit.data);
  }
},


  }
};
</script>