<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900">
    <TopNav @toggle-sidebar="toggleSidebar"></TopNav>

    <!-- Sidebar -->
    <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false"></SideMenu>

    <main class="p-4 md:ml-64 h-auto pt-20">
      <section class="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
        <div class="mx-auto max-w-screen-3xl px-4 lg:px-12">
          <!-- Start coding here -->
          <div class="dark:bg-gray-800 relative sm:rounded-lg overflow-hidden">
            <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
              <div class="w-full max-w-md">
                <form class="flex items-center">
                  <label for="simple-search" class="sr-only">Search</label>
                  <div class="relative w-full">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd" />
                      </svg>
                    </div>
                    <input type="text" id="simple-search" v-model="searchQuery"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Search courses" />
                  </div>
                </form>
              </div>

              <!-- Add Course Button -->
              <div
                class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <a href="/addcourse"
                  class="flex items-center justify-center text-white !bg-primary-700 hover:!bg-primary-800 focus:ring-4 !focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2">
                  <svg class="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true">
                    <path clip-rule="evenodd" fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" />
                  </svg>
                  Add course
                </a>
              </div>
            </div>

            <!-- Loading Spinner -->
            <div v-if="loading" class="text-center py-10">
              <svg aria-hidden="true"
                class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>

            <!-- No Courses Message -->
            <div v-if="!loading && filteredCourses.length === 0"
              class="text-center text-gray-600 dark:text-gray-400 flex flex-col items-center justify-center h-80">
              <h2 class="text-3xl font-semibold mb-4">No Courses Available</h2>
              <p class="text-lg mb-2">It looks like there are no courses available right now.</p>
              <p class="text-md">Please check back later.</p>
            </div>

            <!-- Courses Table -->
            <div v-if="!loading && filteredCourses.length" class="overflow-x-auto">
              <table class="w-full text-md text-left text-gray-500 dark:text-gray-400 bg-white shadow-md mt-5">
                <thead class="text-md text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-4 py-5">Course</th>
                    <th scope="col" class="px-4 py-5">Category</th>
                    <th scope="col" class="px-4 py-5">Last Updated On</th>
                    <th scope="col" class="px-4 py-5"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="course in filteredCourses" :key="course.id"
                    class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                    <td class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <router-link :to="{ name: 'EditCourse', params: { id: course.id } }"
                        class="text-blue-500 hover:underline">
                        {{ course.title }}
                      </router-link>
                    </td>
                    <td class="px-4 py-3">
                      <span v-if="course.categories.length">
                        <span v-for="(category, index) in course.categories" :key="category.id">
                          {{ formatCategoryHierarchy(category) }}{{ index < course.categories.length - 1 ? ', ' : '' }}
                            </span>
                        </span>
                        <span v-else></span>
                    </td>
                    <td class="px-4 py-3">{{ formatDate(course.date) }}</td>
                    <td class="px-4 py-3 text-right">
                      <router-link :to="{ name: 'ViewCourse', params: { id: course.id } }"
                        class="text-blue-600 hover:underline dark:text-blue-400">
                        View
                      </router-link>
                      <router-link :to="{ name: 'EditCourse', params: { id: course.id } }"
                        class="text-green-600 hover:underline dark:text-green-400">
                        Edit
                      </router-link>
                      <button @click="confirmDeleteCourse(course.id, course.title)"
                        class="text-red-600 hover:underline dark:text-red-400 focus:outline-none">
                        Delete
                      </button>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Pagination (optional) -->
            <nav v-if="filteredCourses.length"
              class="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
              aria-label="Table navigation">
              <span class="text-sm font-normal text-gray-500 dark:text-gray-400">Showing <span
                  class="font-semibold text-gray-900 dark:text-white">1-10</span> of <span
                  class="font-semibold text-gray-900 dark:text-white">100</span></span>
              <ul class="inline-flex items-stretch -space-x-px">
                <!-- Pagination buttons can be added here -->
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </main>
  </div>

  <!-- Delete Confirmation Popup -->
  <div v-if="showDeleteModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-lg w-full p-6">
                <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">Confirm Deletion</h3>
                <p class="text-gray-600 dark:text-gray-400 mb-2">
                  Are you sure you want to delete <span class="font-bold">{{ courseTitleToDelete }}</span>?
                </p>
                <p class="text-gray-600 dark:text-gray-400 mb-6">This action cannot be undone.</p>
                <div class="flex justify-end space-x-4">
                  <button @click="showDeleteModal = false" class="px-4 py-2 bg-gray-500 text-white rounded-lg">Cancel</button>
                  <button @click="deleteCourse" class="px-4 py-2 bg-red-600 text-white rounded-lg">Delete</button>
                </div>
              </div>
            </div>


</template>

<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/SideMenu.vue';

export default {
  components: {
    TopNav,
    SideMenu,
  },
  data() {
    return {
      courses: [],
      searchQuery: '',
      loading: true,
      isSidebarOpen: false,
      showDeleteModal: false,
      courseIdToDelete: null,
      courseTitleToDelete: "",
    };
  },
  computed: {
    filteredCourses() {
      let courses = Array.isArray(this.courses) ? this.courses : []; // Ensure courses is always an array

      // Filter by search query
      if (this.searchQuery) {
        const searchLower = this.searchQuery.toLowerCase();
        courses = courses.filter(course => course.title?.toLowerCase().includes(searchLower));
      }

      // Sort courses by date (newest first)
      courses.sort((a, b) => {
        const dateA = new Date(a.date || 0); // Fallback to 0 if date is missing
        const dateB = new Date(b.date || 0);
        return dateB - dateA;
      });

      return courses;
    },
  },
  mounted() {
    this.fetchCourses();
  },
  methods: {
    confirmDeleteCourse(id, title) {
      this.courseIdToDelete = id;
      this.courseTitleToDelete = title;
      this.showDeleteModal = true;
    },

    async deleteCourse() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
          mutation DeleteCourse($id: Int!) {
            deleteCourse(id: $id)
          }
        `,
          variables: { id: parseInt(this.courseIdToDelete) },
        });

        if (response.data.data.deleteCourse) {
          // Remove the deleted course from the list
          this.courses = this.courses.filter(course => course.id !== this.courseIdToDelete);
        }
      } catch (error) {
        console.log("Error deleting course:", error);
      } finally {
        this.showDeleteModal = false;
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    formatCategoryHierarchy(category) {
      let hierarchy = category.name;
      let parent = category.parent;
      while (parent) {
        hierarchy = `${parent.name} > ${hierarchy}`;
        parent = parent.parent;
      }
      return hierarchy;
    },
    formatDate(date) {
      if (!date) return 'N/A';
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    async fetchCourses() {
      const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
          query FetchCourses($branchUrl: String) {
              courses(includeHidden: true, branchUrl: $branchUrl) {
                  id
                  title
                  price
                  categories {
                    id
                    name
                    parent_id
                  }
                  date
                }
              }
            `,
          variables: {
            branchUrl,
          },
        });

        this.courses = response.data.data.courses || [];
      } catch (error) {
        console.error('Error fetching courses:', error);
      } finally {
        this.loading = false; // Set loading to false after data is fetched
      }
    },
  },
};
</script>