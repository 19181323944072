<template>
    <div class="test-slider">
      <!-- Loop over all tests and display them all on the page -->

      <TestSlide 
      v-for="test in tests" 
  :key="test.id" 
  :test="test" 
  :isActive="true"
  :savedAnswers="savedResponses[test.id] || {}" 
  @set-answer="setUserAnswer(test.id, $event)" 
        />

    </div>
  </template>
  
  <script>
  import TestSlide from '@/components/learner/units/TestSlide.vue';
  
  export default {
    props: {
      tests: {
        type: Array,
        required: true,
      },
      savedResponses: {
        type: Object,
        default: () => ({}), // Accept saved responses from parent
      },
    },
    data() {
      const answers = {};
  this.tests.forEach(test => {
    answers[test.id] = null;
  });
      return {
        userAnswers: answers,
      };
    },
    mounted() {
      // Restore saved answers if provided
      this.restoreAnswers(this.savedResponses);
    },
    methods: {
      // Directly assign the full answer object to userAnswers
      setUserAnswer(testId, answer) {
        this.userAnswers[testId] = answer;
        this.$emit('test-answered', testId, answer);
      },
  
      // Restore saved answers when the user navigates back to the unit
      restoreAnswers(savedResponses) {
    this.tests.forEach(test => {
      this.userAnswers[test.id] = savedResponses[test.id] || null;
    });
  },
  
      // Return all answers for checking correctness
      getAllAnswers() {
        return Object.values(this.userAnswers) || [];
      },
    },
    components: {
      TestSlide,
    },
  };
  </script>
  