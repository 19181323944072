<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
      <TopNav @toggle-sidebar="toggleSidebar"  />
      <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />
  
      <main class="p-6 md:ml-64 h-auto pt-20">
        <section class="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
          <div class="mx-auto max-w-screen-3xl px-4 lg:px-12">
            <div class="dark:bg-gray-800 relative sm:rounded-lg overflow-hidden">
              <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                <div class="w-full max-w-md">
                  <form class="flex items-center">
                    <label for="category-search" class="sr-only">Search Categories</label>
                    <div class="relative w-full">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                          <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="category-search"
                        v-model="searchQuery"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        placeholder="Search categories"
                      />
                    </div>
                  </form>
                </div>
                <div class="flex items-center">
                  <router-link :to="{ name: 'CreateCategory' }" class="flex items-center justify-center text-white !bg-primary-700 hover:!bg-primary-800 focus:ring-4 !focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2">
                    + Add Category
                  </router-link>
                </div>
              </div>
  
              <div v-if="loading" class="text-center py-10">
                <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                </svg>
              </div>
  
              <div v-if="!loading && categories.length" class="overflow-x-auto">
                <table class="w-full text-md text-left text-gray-500 dark:text-gray-400 bg-white shadow-md mt-5">
                  <thead class="text-md text-gray-700 bg-gray-200">
                    <tr>
                      <th class="px-4 py-3">Category Name</th>
                      <th class="px-4 py-3">Parent Category</th>
                      <th class="px-4 py-3">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="category in filteredCategories"
                      :key="category.id"
                      class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <td class="px-4 py-3">{{ category.name }}</td>
                      <td class="px-4 py-3">{{ category.parent ? category.parent.name : 'None' }}</td>
                      <td class="px-4 py-3 flex space-x-3">
                        <router-link :to="{ name: 'EditCategory', params: { id: category.id } }" class="text-blue-600 hover:underline">Edit</router-link>
                        <button @click="confirmDeleteCategory(category.id)" class="text-red-600 hover:underline">Delete</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
  
              <!-- Delete Confirmation Popup -->
              <div v-if="showDeleteModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-lg w-full p-6">
                  <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">Confirm Deletion</h3>
                  <p class="text-gray-600 dark:text-gray-400 mb-6">Are you sure you want to delete this category? This action cannot be undone.</p>
                  <div class="flex justify-end space-x-4">
                    <button @click="showDeleteModal = false" class="px-4 py-2 bg-gray-500 text-white rounded-lg">Cancel</button>
                    <button @click="deleteCategory" class="px-4 py-2 bg-red-600 text-white rounded-lg">Delete</button>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
        </section>
      </main>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import TopNav from '@/components/headers/TopNav.vue';
  import SideMenu from '@/components/SideMenu.vue';
  
  export default {
    components: { TopNav, SideMenu },
    data() {
      return {
        categories: [],
        searchQuery: '',
        loading: true,
        showDeleteModal: false,
        categoryIdToDelete: null,
        isSidebarOpen: false,
      };
    },
    computed: {
      filteredCategories() {
        return this.categories.filter(category =>
          category.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      },
    },
    methods: {
      toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
      async fetchCategories() {
        this.loading = true;
        try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
            query: `query { categories { id name parent { id name } } }`,
          });
          this.categories = response.data.data.categories || [];
        } catch (error) {
          console.error('Error fetching categories:', error);
        } finally {
          this.loading = false;
        }
      },
      confirmDeleteCategory(id) {
        this.categoryIdToDelete = id;
        this.showDeleteModal = true;
      },
      async deleteCategory() {
        const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

        try {
          await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
            query: `
              mutation DeleteCategory($id: Int!, $branchUrl: String) {
                deleteCategory(id: $id, branchUrl: $branchUrl)
              }
            `,
            variables: { id: parseInt(this.categoryIdToDelete), branchUrl },
          });
          this.categories = this.categories.filter(cat => cat.id !== this.categoryIdToDelete);
        } catch (error) {
          console.error('Error deleting category:', error);
        } finally {
          this.showDeleteModal = false;
        }
      },
    },
    created() {
      this.fetchCategories();
    },
  };
  </script>
  